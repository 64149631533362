import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { url: String, timeout: { type: Number, default: 0 } }

  connect () {
    setTimeout(() => this.fetchAndDisplayFrame(), this.timeoutValue)
  }

  fetchAndDisplayFrame() {
    fetch(this.urlValue, { method: 'GET',
                           headers: { 'Accept': 'text/html' }})
    .then(response => {
      if (response.status === 200) {
        return response.text();
      } else {
        setTimeout(() => this.fetchAndDisplayFrame(), 5000)
        return null
      }
    })
    .then((html) => {
      if (html !== null) {
        this.element.innerHTML = html
      }
    });
  }
}
