import { localeFromURL } from '../components/utilities'
import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { id: String, round: String, controller: String }
  redirect () {
    if (this.controllerValue === 'receptions') {
      window.location.href = `/${localeFromURL()}/${this.controllerValue}/${this.idValue}?round_id=${this.roundValue}&source=round`
    } else {
      window.location.href = `/${localeFromURL()}/${this.controllerValue}/${this.idValue}?round_id=${this.roundValue}`
    }
  }
}
