import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    timeout: Number
  }

  connect () {
    this.#setTimeout()

    // In case of refresh we might have cleared the session
    // since we can have loaded the page before the quit request processed,
    // so we send heartbeat after connecting to ensure we have an open session.
    setTimeout(() => { this.heartbeat() }, 5000)
  }

  timeout() {
    alert('La session est sur le point de se terminer. Veuillez fermer cette fenêtre pour continuer.')
    this.heartbeat()
  }

  heartbeat() {
    fetch(this.urlValue, { redirect: 'manual'})
      .then(data => {
        if (data.type === 'opaqueredirect') {
          window.location.reload(true)
        }
      }).catch(error => alert(error))

    this.#setTimeout()
  }

  quit(event) {
    var data = new FormData();
    data.append("beacon", "true");

    navigator.sendBeacon(document.querySelector('.action_items a').href, data)
  }

  #setTimeout() {
    clearTimeout(this.timeoutTimer)
    this.timeoutTimer = setTimeout(() => { this.timeout() }, this.timeoutValue * 1000)
  }
}
