import ApplicationController from './application_controller'

/*
 * Values:
    language: plugin language, FR, EN, ES, IT, DE, PT are possible options
    url: requires ENV('COLISSIMO_BASE_ENDPOINT')
    countryCode: address country
    rawAddress: address raw address
    postalCode: address postal code
    city: address city
    token: use PORO Colissimo::AuthenticationToken
    countryList: string, list of countries where relay point can be found, FR,PT,DE,GB,BE,ES
 * Notes:
    It is used as of now in product widget, protected under the feature flag :relay_point
    A CallBackFrame method is injected in the HTML to be used by the plugin when a relay point is selected
    It fetches RelayPoint#create to create the relay point address with Colissimo information
    Once the relay point is create, the turbo frame "price_details" is updated by validating a form, calling PriceDetails#update
 * EXAMPLE: see _relay_point_modal.html.erb
 */
export default class extends ApplicationController {
  static targets = ['container', 'submit', 'selectedCarrier']
  static values = {
    language: String,
    url: String,
    callbackUrl: String,
    countryCode: String,
    rawAddress: String,
    postalCode: String,
    city: String,
    token: String,
    leadRef: String,
    countryList: String,
    eventToDispatchAfterInit: String,
    eventToDispatchAfterSubmit: String
  }
  static outlets = ['modal-tailwind']

  connect () {
    const element = this.element

    window.callBackFrame = function (point) {
      element.dataset.point = JSON.stringify(point)
      element.dispatchEvent(new Event('relay-point-selected'))
    }

    element.addEventListener('relay-point-selected', () => { this.update() })
    setTimeout(() => {
      this.initializeFrame()
      this.containerTarget.style.height = 'fit-content'
    }, 500)
  }

  update () {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value
    const point = JSON.parse(this.element.dataset.point)
    fetch(this.callbackUrlValue, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify(point)
    }).then(response => response.json())
      .then(data => {
        if (data.status === 'ok') {
          this.submitColissimoPickAndDispatchEvent()
        } else {
          // Closing the modal if we're getting an error
          console.error(data.message)
          jQuery(`#${this.containerTarget.id}`).frameColissimoClose()
          this.modalTailwindOutlet.hideModal()
        }
      })
  }

  // requires loaded Script for jquery and jquery.plugin.colissimo.min.js
  initializeFrame () {
    jQuery(`#${this.containerTarget.id}`).frameColissimoOpen({
      'ceLang': this.languageValue,
      'callBackFrame': 'callBackFrame',
      'URLColissimo': this.urlValue,
      'dyPreparationTime': '1',
      'ceCountryList': this.countryListValue,
      'ceCountry': this.countryCodeValue,
      'ceAddress': this.rawAddressValue,
      'ceZipCode': this.postalCodeValue,
      'ceTown': this.cityValue,
      'token': this.tokenValue })

    document.dispatchEvent(new Event(this.eventToDispatchAfterInitValue))

    this.element.querySelector('.widget_colissimo_close').style.display = 'none'
    this.element.querySelectorAll('td.widget_colissimo_table_adresse').forEach((element) => { element.style.display = 'none' })
    this.element.querySelector('#widget_colissimo_text_erreur').style.display = 'none'

    this.element.addEventListener('click', () => {
      this.element.querySelectorAll('.widget_colissimo_bouton_validation').forEach((e) => {
        e.style.height = 'fit-content'
        e.style.padding = '10px 0px'
      })
    })
  }

  submitColissimoPick () {
    this.submitTarget.click()
    jQuery(`#${this.containerTarget.id}`).frameColissimoClose()
  }

  submitColissimoPickAndDispatchEvent () {
    this.submitColissimoPick()

    document.dispatchEvent(new Event(this.eventToDispatchAfterSubmitValue))
  }
}
