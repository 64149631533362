import ApplicationController from './application_controller'
import setNewPriceDetails from '../utils/set-new-price-details'

/* TODO: Au retrait de la feature flag, renommer ce controller avec un meilleur nom (ex: sync_options_and_compute_new_price) */
/*
 * EXAMPLE:
 * <div data-controller="order">
 *   <%= f.input :white_glove, 'data-order-target': 'white_glove', 'data-order-action': "change->order#update", as: :hidden %>
 *   <ul>
 *     <li data-order-target="option_white_glove">
 *       White Glove
 *     </li>
 *   </ul>
 * </div>
 */
export default class extends ApplicationController {
  static targets = [
    'insured',
    'white_glove',
    'cites',
    'export_licence',
    'cultural_certificate',
    'packing',
    'option_insured',
    'option_white_glove',
    'option_cites',
    'option_export_licence',
    'option_cultural_certificate',
    'option_packing',
  ]

  static values = {
    hiddenClass: { type: String, default: 'd-none' },
    visibleClass: { type: String, default: 'd-block' },
  }

  connect() {
    this.update()
  }
  update() {
    this.syncOptions()
    // Add 250ms delay for prevent google auto-completition problem
    setTimeout(() => {
      const url = this.data.get('url')

      let orderForm = document.getElementById('edit-order')
      let formData = new FormData(orderForm)

      fetch(url, {
        method: this.data.get('method'),
        body: formData
      }).then(response => {
        document
          .querySelectorAll('.is-invalid')
          .forEach(element => element.classList.remove('is-invalid'))
        document
          .querySelectorAll('.alert-danger')
          .forEach(element => element.remove())
        if (response.ok) {
          response.json().then(data => setNewPriceDetails(data))
        }
        if (response.status === 422) {
          response.json().then(data => this.displayErrors(data))
        }
      })
    }, 100)
  }

  removeItem(event) {
    event.preventDefault()
    const baseUrl = this.data.get('url')
    const itemId = parseInt(event.target.dataset.id)
    const url = `${baseUrl}/items/${itemId}`

    Rails.ajax({
      type: 'delete',
      url: url,
      success: function (data) {
        const itemField = document.getElementById(`order-item-${itemId}`)
        itemField.remove()
        setNewPriceDetails(data)
      }
    })
  }

  displayErrors(data) {
    for (const [key, value] of Object.entries(data)) {
      var input = document.getElementById(`order_${key}`)

      if (input) {
        input.classList.add('is-invalid')
        const temp = document.createElement('div')
        temp.innerHTML = value
        const htmlObject = temp.firstChild
        input.insertAdjacentElement('afterend', htmlObject)
      }
    }
  }

  syncOptions() {
    this.syncInsured()
    this.syncPacking()
    this.syncWhiteGlove()
    this.syncCites()
    this.syncExportLicence()
    this.syncCulturalCertificate()
  }

  syncInsured = () => (this.option_insuredTarget.hidden = !this.insuredTarget.checked)
  syncPacking = () => (this.option_packingTarget.hidden = !this.packingTarget.checked)
  syncWhiteGlove = () => this.toggleVisibility(this.white_gloveTarget, this.option_white_gloveTarget)
  syncCites = () => this.toggleVisibility(this.citesTarget, this.option_citesTarget)
  syncExportLicence = () => this.toggleVisibility(this.export_licenceTarget, this.option_export_licenceTarget)
  syncCulturalCertificate = () => this.toggleVisibility(this.cultural_certificateTarget, this.option_cultural_certificateTarget)

  toggleVisibility(HTMLInputElement, HTMLElement) {
    if (HTMLInputElement.checked) {
      HTMLElement.classList.remove(this.hiddenClassValue)
      HTMLElement.classList.add(this.visibleClassValue)
    }
    else {
      HTMLElement.classList.remove(this.visibleClassValue)
      HTMLElement.classList.add(this.hiddenClassValue)
    }
  }
}
