import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  connect () {
    this.sortable = Sortable.create(this.element, {
      handle: '.drag-and-drop-handle',
      animation: 150,
      easing: 'cubic-bezier(1, 0, 0, 1)',
      onEnd: this.changePosition.bind(this)
    })
  }

  changePosition (event) {
    let id = event.item.dataset.id
    let type = event.item.dataset.type
    let data = new FormData()

    data.append('position', event.newIndex + 1)
    data.append('type', type)
    Rails.ajax({
      url: this.data.get('url').replace(':id', id),
      type: 'PATCH',
      data: data
    })
  }
}
