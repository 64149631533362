import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { eventName: String }

  dispatchEvent (e) {
    const eventToDispatch = new Event(this.eventNameValue)
    document.dispatchEvent(eventToDispatch)
  }

  dispatchEventAndPrevent (e) {
    e.preventDefault()
    this.dispatchEvent(e)
  }
}
