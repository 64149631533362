import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form']
  static values = { event: String }

  formTargetConnected () {
    this.formTarget.addEventListener(this.eventValue, () => {
      this.submit()
    })
  }

  submit () {
    this.formTarget.requestSubmit()
  }
}
