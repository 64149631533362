import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Focus an element when calling the focus method
 *
 * EXAMPLE:
 * <div data-controller="focus-element">
 *  <button data-action="focus-element#focus"></button>
 *  <%= text_field_tag 'name', nil, data: { 'focus-element-target': 'element' } %>
 * </div>
 */

export default class extends ApplicationController {
  static targets = ['element']

  focus () {
    this.elementTarget.focus()
  }

  focusAndSelect () {
    this.elementTarget.focus()
    this.elementTarget.select()
  }
}
