import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Copy the values of the input fields that has a data-source corresponding to the data-copy-source of the target, into the output fields.
 *
 * TARGETS:
 * input: The fields where the values will be copied FROM.
 * output: The fields where the value will be copied IN.
 *
 * DATA ATTRIBUTES:
 * copy-source: Indicates what will be the source of the copy. For example if it's "data-copy-source='delivery'", then it'll copy the input fields where "data-source" == "delivery".
 * description: Description of the field, it's a way to find the input that corresponds to the output.
 * source: Represents the source of the input. For example an input that has "data-source='delivery'" means that it belongs to the Delivery source.
 *
 * EXAMPLE:
 * Here we're going to copy the value of the input#delivery_address into the input#billing_address when the button#copy_delivery is clicked.
 *
 * <div data-controller="copy-fields">
 *  <button id="copy_delivery" data-action="copy-fields#copy" data-copy-source="delivery">
 *    Copy Delivery Address into the Billing Address
 *  </button>
 *  <input id="delivery_address" data-description="rawAddress" data-copy-fields-target="input" data-source="delivery">
 *  <input id="billing_address" data-description="rawAddress" data-copy-fields-target="input" data-source="billing">
 * </div>
 *
 * NOTE: If you want to use this controller in a form, it's better to use FormCopyFields
 */

export default class CopyFieldsController extends ApplicationController {
  static targets = ['input', 'output']

  copy (e) {
    if (this.hasInputTarget && this.hasOutputTarget) {
      this.copyInputIntoOutput(e.target.dataset.copySource, e.target.dataset.separation)
    }
  }

  copyInputIntoOutput (source, separation) {
    if (source) {
      // Filter inputsTarget to get only the inputs where the data-source is equal to the data-copy-source of the formTarget
      const inputstargets = this.inputTargets.filter(element => { return element.getAttribute('data-source') === source })
      this.copyField(inputstargets, separation)
    }
  }

  copyField (inputTargets, separation) {
    this.outputTargets.forEach(output => {
      // Retrieve the input where the data-description is equal to the output's one
      let inputElement = inputTargets.find(input => { return output.dataset.description.includes(input.dataset.description) })
      
      output.value = inputElement.value
    })
  }
}
