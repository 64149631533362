import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Remove the values of the input fields when calling the remove method.
 *
 * TARGETS:
 * input: The fields where the values will be erased.
 *
 * EXAMPLE: Here we're going to remove all values from the inputs when clicking on the button.
 *
 * <div data-controller"remove-fields">
 *  <button id="remove_button" data-action="remove-fields#remove">
 *    Remove values from fields
 *  </button>
 *  <input id="delivery_address" data-remove-fields-target="input" value="Lorem">
 *  <input id="billing_address" data-remove-fields-targetn="input" value="Ipsum">
 * </div>
 */

export default class extends ApplicationController {
  static targets = ['input']

  remove () {
    this.inputTargets.forEach(input => {
      input.value = ''
    })
  }
}
