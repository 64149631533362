import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'displayedPrice', 'transportPrice', 'saveButton', 'form']

  connect () {
    this.displayPrice()
  }

  displayPrice (event) {
    const requiredTargetsNotFilled = (targets) => targets.map(el => !!el.value).includes(false)

    if (requiredTargetsNotFilled(this.inputTargets)) {
      this.displayedPriceTarget.innerHTML = '--,--'
      this.transportPriceTarget.innerHTML = '--,--'
      this.saveButtonTarget.disabled = true
    } else {
      var url = `/product_quotations`
      fetch(url, {
        method: 'POST',
        body: new FormData(this.formTarget)
      })
        .then(response => {
          if (response.ok) { response.json().then(data => this.setPrice(data)) }
        })
    }
  }

  setPrice (data) {
    this.displayedPriceTarget.innerHTML = data['price']
    this.transportPriceTarget.innerHTML = data['transport_price']
    this.saveButtonTarget.disabled = false
  }
}
