import ApplicationController from './application_controller'
import SignaturePad from 'signature_pad'
import urlToFile from '../utils/url-to-file'

export default class extends ApplicationController {
  static targets = ['canvas', 'fileInput']

  connect () {
    this.signature = new SignaturePad(this.canvasTarget, {
      penColor: 'rgb(0, 0, 0)',
      backgroundColor: 'rgb(255, 255, 255)', // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
      onEnd: () => {}
    })
    this.signature.addEventListener("endStroke", () => {
      this.attachSignatureFileToFileInput();
    });
  }

  save () {
    if (this.signature.isEmpty()) {
      return null
    } else {
      const signatureB64 = this.signature.toDataURL('image/jpeg')
      const signatureFile = urlToFile(signatureB64, 'signature.jpeg', 'image/jpeg')
      return signatureFile
    }
  }

  clear () {
    this.signature.clear()
    this.fileInputTarget.value = null
  }

  attachSignatureFileToFileInput () {
    const uploadSignature = this.save()
    uploadSignature.then((signature) => {
      let signatureList = new DataTransfer()
      signatureList.items.add(signature)
      this.fileInputTarget.files = signatureList.files
    });
  }
}
