import ApplicationController from './application_controller'

/*
 * Important :
 * If this.centerMapOnMarkers() is not used, it is mandatory to provide a value
 * to the "center" parameter for the map to be displayed correctly.
 */
export default class extends ApplicationController {
  static targets = ['output']
  static values = {
    type: String,
    latLngStart: Object,
    latLngEnd: Object,

    // To make an itinerary from a classic address (no lng/lat)
    addressStart: String,
    waypoints: Array, // Array of addresses that represent waypoints in an itinerary, do not include departure and arrival address
    addressEnd: String
  }

  connect () {
    if (this.typeValue === 'order-tracking') {
      this.initOrderTrackingMap()
    } else if (this.typeValue === 'round-delivery-from-warehouse') {
      this.initRoundDeliveryFromWarehouse()
    } else {
      console.log('The "type" value must be defined for this controller, e.g.: data-google-map-type-value="order-tracking".')
    }
  }

  initRoundDeliveryFromWarehouse () {
    const directionsService = new google.maps.DirectionsService()
    const directionsRenderer = new google.maps.DirectionsRenderer()

    this.map = new google.maps.Map(this.outputTarget, {
      zoom: 8,
      center: {
        lat: 50.605412,
        lng: 1.793390
      }
    })

    directionsRenderer.setMap(this.map)

    const startAddress = this.addressStartValue
    const waypoints = []
    const endAddress = this.addressEndValue

    this.waypointsValue.forEach((waypoint) => {
      waypoints.push({
        location: waypoint,
        stopover: true
      })
    })

    directionsService
      .route({
        origin: startAddress,
        destination: endAddress,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING
      })
      .then((response) => {
        directionsRenderer.setDirections(response)
      })
      .catch((e) => 'Directions request failed due to ' + e)
  }

  initOrderTrackingMap () {
    this.map = new google.maps.Map(this.outputTarget, {
      zoom: 4
    })
    this.initMarkers()
    this.drawLineBetweenMarkers()
    this.centerMapOnMarkers()
  }

  initMarkers () {
    this.markers = []

    this.markers.push(
      new google.maps.Marker({
        position: this.latLngStartValue,
        map: this.map
      })
    )

    this.markers.push(
      new google.maps.Marker({
        position: this.latLngEndValue,
        map: this.map,
        fillColor: 'yellow'
      })
    )
  }

  drawLineBetweenMarkers () {
    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
    }
    // Create the polyline and add the symbol via the 'icons' property.
    new google.maps.Polyline({
      path: [
        this.markers[0].position,
        this.markers[1].position
      ],
      icons: [{
        icon: lineSymbol,
        offset: '100%'
      }],
      map: this.map
    })
  }

  centerMapOnMarkers () {
    var bounds = new google.maps.LatLngBounds()

    this.markers.forEach(function (marker) {
      bounds.extend(marker.position)
    })

    this.map.fitBounds(bounds)
  }
}
