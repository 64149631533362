import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["category", "tagList"]

  connect() {
    if (this.hasCategoryTarget && this.hasTagListTarget) {
      this.updateTags()
    }
  }

  updateTags() {
    const category = this.categoryTarget.value
    const currentSelection = Array.from(this.tagListTarget.selectedOptions).map(option => option.value)

    fetch(`/admin/incidents/tags_for_category?category=${category}`)
      .then(response => response.json())
      .then(tags => {
        this.tagListTarget.innerHTML = ''
        tags.forEach(([label, value]) => {
          const option = new Option(label, value)
          option.selected = currentSelection.includes(value)
          this.tagListTarget.add(option)
        })
      })
  }
}
