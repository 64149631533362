import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['values', 'submit', 'error'];

  connect () {
    this.disableButtonAndDisplayMessageError()
  }

  disableButtonAndDisplayMessageError () {
    if (this.valuesTarget.value.split(/\D+/g).length > 1000) {
      this.submitTarget.disabled = true
      this.errorTarget.textContent = 'This list cannot contain more than 1000 items'
    } else {
      this.submitTarget.disabled = false
      this.errorTarget.textContent = ''
    }
  }
}
