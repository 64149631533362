import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'label' ]
  static values = { placeholder: String, placeholderPlural: String }

  updateLabel (event) {
    const numberOfFiles = event.currentTarget.files.length

    if (numberOfFiles > 1) {
      this.labelTarget.innerHTML = this.placeholderPluralValue.replace('|JAVASCRIPT_NUMBER|', numberOfFiles)
    } else {
      this.labelTarget.innerHTML = this.placeholderValue
    }
  }
}
