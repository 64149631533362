import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['organizationId', 'ownerId', 'organizationPickupAddressInput', 'otherAddressDiv', 'pickupAddressInput']
  static values = { url: String, orderId: String }

  connect () {
    this.toggleOtherAddress()
  }

  fetchAddresses () {
    const organizationId = this.organizationIdTarget.value
    const ownerId = this.ownerIdTarget.value
    if ((organizationId === null || organizationId === '') || (ownerId === null || ownerId === '')) { return }

    const url = new URL(this.urlValue)
    const params = { id: organizationId, owner_id: ownerId, order_id: this.orderIdValue }
    url.search = new URLSearchParams(params).toString()
    this.clearOptions()
    this.pickupAddressInputTarget.value = ''
    fetch(url)
      .then(response => response.json())
      .then(({ pickingAddress, billingAddress }) => {
        pickingAddress.allAddresses.forEach((address) => {
          this.organizationPickupAddressInputTarget.innerHTML += address
        })
        this.organizationPickupAddressInputTarget.innerHTML += '<option value=\'other_address\'>Other Address...</option>'
        this.toggleOtherAddress()
      })
  }

  toggleOtherAddress () {
    var select = this.organizationPickupAddressInputTarget
    if (select.value === 'other_address') {
      this.otherAddressDivTarget.style.display = 'block'
    } else {
      this.otherAddressDivTarget.style.display = 'none'
      var option = select.options[select.selectedIndex]
      if (option === undefined) { return }
      this.pickupAddressInputTarget.value = option.value
    }
  }

  removePickupAddress () {
    var select = this.organizationPickupAddressInputTarget
    if (select.value === 'other_address') {
      this.pickupAddressInputTarget.value = ''
    }
  }

  clearOptions () {
    var select = this.organizationPickupAddressInputTarget
    var length = select.options.length
    for (var i = length - 1; i >= 0; i--) {
      select.options[i] = null
    }
  }
}
