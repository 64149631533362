import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { with: String }
  connect () {
    this.element.dataset['action'] = 'submit->disable#disableForm'

    if (!this.hasWithValue) {
      this.withValue = '...'
    }
  }

  disableForm () {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons () {
    return this.element.querySelectorAll("input[type='submit']")
  }

  disableButton () {
    event.currentTarget.disabled = true
  }
}
