import { Controller } from "@hotwired/stimulus"

export default class ToggleSortFieldsController extends Controller {
  static targets = ['input']

  connect() {
    this.maxOrder = this.findMaxPosition() === 0 ? 0 : this.findMaxPosition() + 1
    this.order = this.maxOrder
  }

  updateHiddenFields(event) {
    const target = event.target
    const changeAttribute = target.dataset.attributeChange
    const activeValue = target.dataset.activeValue
    const inactiveValue = target.dataset.inactiveValue

    if(target.getAttribute(changeAttribute) === inactiveValue) {
      target.setAttribute(changeAttribute, `${activeValue}[${this.order}]`)
      this.order += 1
    } else {
      target.setAttribute(changeAttribute, inactiveValue)
      this.order -= 1
    }
  }

  findMaxPosition() {
    let array = []
    this.inputTargets.filter(element => element.checked).forEach(element => array.push(parseInt(element.dataset.orderValue)))

    return array.length === 0 ? 0 : Math.max(...array)
  }
}