import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['date_datetimepicker', 'near', 'displayDateDiv'];

  connect () {
    this.changeFormatDate()
    this.displayDate()
  }

  printDelivery () {
    this.nearTarget.style.display = 'none'
  }

  changeFormatDate () {
    var value = this.nearTarget.children[0]
    var country = this.nearTarget.getAttribute('data-info')
    var format
    var formatDisplay
    var date

    if (country === 'fr') {
      format = 'dddd Do MMMM YYYY, hh:mm:ss'
      formatDisplay = 'DD-MM-YYYY'
    } else {
      format = 'dddd, MMMM Do YYYY, h:mm:ss a'
      formatDisplay = 'YYYY-MM-DD'
    }

    if (value.value === '') {
      date = moment().locale(country).format(formatDisplay)
    } else {
      date = moment(value.value, format, country).locale(country).format(formatDisplay)
    }

    value.value = date
  }

  displayDate () {
    var val1 = this.displayDateDivTarget

    setTimeout(() => {
      var val = this.nearTarget.children[0].value
      val1.innerHTML = val
    }
    , 100)
  }
}
