import ApplicationController from './application_controller'

/*
 * Values:
 *   identifier: An unique string ID used like a scope, call this controller with "admin-sidebar" as identifier...
 *               enable keyboard shortcuts for the admin sidebar.
 *
 * EXAMPLE:
 *
 * <div data-controller="close-sidebar" data-close-sidebar-identifier-value="admin-sidebar">
 *    ...
 * </div>
 */
export default class extends ApplicationController {
  static values = { identifier: String, keyCode: String }

  // Map of identifiers to callback functions
  KEYBOARD_SHORTCUT_CALLBACKS = {
    'legacy-admin-sidebar': (e) => {
      // LEGACY
      if (e.code === 'Escape') {
        document.querySelector('.admin-sidebar').classList.remove('show')
        this.#sidebarCookieClose()
      }
    },
    'admin-sidebar': (e) => {
      if (e.code !== 'Escape') return;

      this.#closeAdminSidebar()
    }
  };

  KEYDOWN_EVENT = 'keydown';

  #callback = null
  #keyCode = null

  connect() {
    this.#keyCode = this.hasKeyCodeValue ? this.keyCodeValue : 'Escape'

    if (!(this.identifierValue in this.KEYBOARD_SHORTCUT_CALLBACKS)) {
      console.error(`Identifier "${this.identifierValue}" is not a valid keyboard shortcut callback identifier.`);
      return;
    }

    this.#callback = this.KEYBOARD_SHORTCUT_CALLBACKS[this.identifierValue]
    document.addEventListener(this.KEYDOWN_EVENT, this.#callback)
  }

  disconnect() {
    if (!this.#callback) return;

    document.removeEventListener(this.KEYDOWN_EVENT, this.#callback)
    this.#callback = null
  }

  #closeAdminSidebar() {
    const elem = document.getElementById('admin-sidebar');

    if (!elem) return;

    elem.classList.remove('admin-sidebar-open')
    elem.classList.add('admin-sidebar-closed')
    this.#sidebarCookieClose()
  }

  #sidebarCookieClose() {
    document.cookie = 'admin_sidebar_open=false;path=/;expires=Session'
  }
}
