import ApplicationController from './application_controller'

export default class PollingController extends ApplicationController {
  static values = {
    url: String,
    selectors: String,
    formSelector: String,
    from: String
  }

  connect() {
    const timeout = this.urlValue.includes('poll=true') ? 2000 : 0

    this.timeout = setTimeout(() => this.polling(), timeout)
  }

  disconnect() {
    clearTimeout(this.timeout)
  }

  polling() {
    fetch(this.url(), this.requestParameters())
      .then(response => response.text())
      .then(html => {
        let dom = new DOMParser().parseFromString(html, 'text/html')
        this.selectorsValue.split(',').forEach(selector => {
          let elementToReplace = document.querySelector(`.${selector}`)
          if (elementToReplace !== undefined) {
            elementToReplace.outerHTML = dom.querySelector(`.${selector}`).outerHTML
          }
        })
      })
  }

  requestParameters() {
    if (this.hasFormSelectorValue) {
      let form = document.querySelector(this.formSelectorValue)
      let formData = new FormData(form)
      return {
        method: 'POST',
        body: formData,
        headers: { Accept: 'text/html' }
      }
    } else {
      return { headers: { Accept: 'text/html' } }
    }
  }

  url() {
    if (this.hasFormSelectorValue) {
      let form = document.querySelector(this.formSelectorValue)
      return form.action
    } else {
      let url = this.hasFromValue ? this.urlValue : `${this.urlValue}?from=js`

      return url.includes('poll=true') ? url : `${url}&poll=true`
    }
  }
}
