import ApplicationController from './application_controller'


// input: 'requirements' is a json: keys = value of select, values = array of fields to display
// output: fields in values are displayed, fields not mentioned are hidden
// ex: requirements: '{ customs: %w[customs zone], insurance: %w[insurance],
//                      floor_delivery: %w[floor_delivery zone],
//                      fees: %w[fees], value_factor: %w[value_factor] }'
export default class extends ApplicationController {
  static targets = ["select", "field"];
  static values = { requirements: String }

  connect() {
    this.onChange();
  }

  onChange() {
    const selectedValue = this.selectTarget.value
    const requirements = JSON.parse(this.requirementsValue)

    this.hideAllFields();

    this.fieldTargets.forEach(() => {
      if (requirements[selectedValue]) {
        this.showFields(requirements[selectedValue])
      }
    });
  }

  hideAllFields() {
    this.fieldTargets.forEach((field) => {
      field.style.display = "none";
    });
  }

  showFields(fields) {
    fields.forEach((fieldName) => {
      const field = document.querySelector(`[data-name="${fieldName}"]`)
      if (field) {
        field.style.display = "block";
      }
    })
  }
}
