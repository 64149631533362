import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
    queryParamsMap: String,
    sourceSelector: String
  }

  static targets = ['destination']

  update() {
    fetch(this.urlWithParams(), { headers: { "Accept": "text/html" } })
      .then(response => response.text())
      .then(html => {
        const dom = new DOMParser().parseFromString(html, "text/html")

        let sourceHTML = null
        try {
          sourceHTML = dom.querySelector(this.sourceSelectorValue).outerHTML
          this.destinationTarget.outerHTML = sourceHTML
        } catch {
          window.location = this.urlWithParams()
        }
      })
  }

  urlWithParams() {
    if (!this.hasQueryParamsMapValue) { return this.urlValue }

    let map = JSON.parse(this.queryParamsMapValue)
    let queryParams = {}

    for (const [key, value] of Object.entries(map)) {
      queryParams[key] = document.querySelector(value).value
    }

    const url = new URL(this.urlValue);
    url.search = new URLSearchParams(queryParams);

    return url
  }
}
