import ApplicationController from './application_controller'

/**
 * Values:
 *    url: URL to share from the browser
 * 
 * Note: Since only Safari (no matter the version) supports the browser sharing, it's preferable to hide the share button 
 * and show it only if it's available. (See example)
 * Example:
 * <button class="hidden"
 *         data-controller="share-url" 
 *         data-action="share-url#share"
 *         data-share-url-url-value="https://thepackengers.com"
 *         data-share-url-hidden-class-value="hidden">
 * </button>
 */

export default class extends ApplicationController {
  static values = {
    url: String,
    hiddenClass: String
  }

  connect() {
    if (navigator.canShare) {
      this.element.classList.toggle(this.hiddenClassValue)
      // Hide external elements
      document.querySelectorAll('[data-hide-if-browser-sharing-supported]')
      .forEach(el => el.classList.toggle(this.hiddenClassValue))
    }
  }

  share() {
    if (navigator.canShare) {
      navigator.share({
        url: this.urlValue
      })
    }
  }
}
