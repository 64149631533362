import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    this.api_key = this.data.get('publishable-key')

    const params = new URLSearchParams(window.location.search)

    if (params.get('success')) {
      let alert = document.createElement('div', 'This order is already paid.')
      alert.setAttribute('class', 'alert alert-warning')
      alert.appendChild(
        document.createTextNode(
          'This order appears to be already paid but we did not yet receive ' +
          'a confirmation with the transaction details from our payment provider. ' +
          'If this message persists after refreshing this page, please ' +
          'and contact our support team at hello@thepackengers.com'
        )
      )

      this.element.replaceWith(alert)
    }

    // Run checkout when connecting to the controller
    if (this.element.hasAttribute('data-checkout-on-start')) {
      this.checkout()
    }
  }

  checkout (event) {
    if (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    var requestParams = this.stripeSessionRequestParams()

    fetch(requestParams.url, requestParams.params)
      .then(response => {
        if (response.ok) { this.openStripeSession(response.json()) }
        if (response.status === 422) {
          response.json().then(response => alert(response.error.message))
        }
      })
  }

  openStripeSession (json) {
    var stripe = Stripe(this.api_key)

    json
      .then(session => stripe.redirectToCheckout({ sessionId: session.reference }))
      .then(result => {
        // If `redirectToCheckout` fails due to a browser or network
        // error, you should display the localized error message to your
        // customer using `error.message`.
        if (result.error) {
          console.log(result.error.message)
          alert(result.error.message)
        };
      })
      .catch(error => console.error('Error:', error))
  }

  stripeSessionRequestParams () {
    var orderId = this.data.get('order-id')
    var orderToken = this.data.get('order-token')

    var bodyParams = new URLSearchParams()
    bodyParams.set('token', orderToken)

    return { url: `/${this.data.get('locale')}/orders/${orderId}/stripe_session`,
      params: {
        method: 'POST',
        body: bodyParams,
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': this.getMetaValue('csrf-token')
        }
      }
    }
  }

  getMetaValue (name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)

    if (element) { return element.getAttribute('content') }
  }
}
