import ApplicationController from './application_controller'
import { truncateString } from '../components/truncate_string'

export default class extends ApplicationController {
  static targets = ['ownerId', 'ownerPickingAddress', 'orderStatus', 'inTransitAt', 'orderPaymentStatus',  'refundedAt', 'principal', 'output', 'input']
  static values = { id: String }

  connect () {
    if (this.hasInTransitAtTarget) {
      this.reachedInTransit = (this.inTransitAtTarget.value !== '')
    }

    if (this.hasRefundedAtTarget) {
      this.reachedRefundedAt = (this.refundedAtTarget.value !== '')
    }

    if (this.hasPrincipalTarget) {
      const outputsCount = this.outputTargets.length

      let checkedOutputs = this.outputTargets.filter(output => output.checked).length
      if (checkedOutputs === outputsCount) { this.principalTarget.checked = true}
    }
  }

  fetchOwnerPickingAddresses () {
    const url = '/addresses/owner_picking_addresses?owner_id=' + this.ownerIdTarget.value
    fetch(url)
      .then(response => response.json())
      .then(({ addresses }) => {
        this.ownerPickingAddressTarget.innerHTML = '<option></option>'
        addresses.forEach((addresses) => {
          const option = document.createElement('option')
          option.value = addresses[0]
          option.innerHTML = truncateString(addresses[1], 100)
          this.ownerPickingAddressTarget.appendChild(option)
        })
      })
  }

  autoFillInTransitAt () {
    if (this.reachedInTransit) {
      return
    }

    if (this.orderStatusTarget.value === 'in_transit') {
      let date = new Date().toISOString().replace('T', ' ').substring(0, 19)
      this.inTransitAtTarget.value = date
    } else {
      this.inTransitAtTarget.value = ''
    }
  }

  autoFillRefundedAt () {
    if (this.reachedRefundedAt) {
      return
    }

    if (this.orderPaymentStatusTarget.value === 'refunded') {
      let date = new Date().toISOString().replace('T', ' ').substring(0, 19)
      this.refundedAtTarget.value = date
    } else {
      this.refundedAtTarget.value = ''
    }
  }
  autoFillData () {
    setTimeout(() => {
      const el = $('.has_many_container .has_many_fields:last fieldset')
      const random = Math.random().toString(20).substring(2, 20)
      el.attr('data-controller', 'address-completion places-autocomplete')
      el.attr('data-places-autocomplete-role', 'picking_' + random)
      el.attr('data-address-completion-listen-to', 'place_autocomplete.picking_' + random)
    }, 100)
  }

  checkThemAll () {
    if (this.hasPrincipalTarget) {
      this.outputTargets.forEach(output => { output.checked = this.principalTarget.checked })
    }
  }

   enabledOutput () {
    if (this.hasInputTarget) { 
      document.getElementById(this.idValue).disabled = (this.inputTarget.value === 'Yes')
    }
  }
}
