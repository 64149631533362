import ApplicationController from './application_controller';


export default class extends ApplicationController {
  connect() {
    this.initializeSlick()
  }

  initializeSlick() {
    if (typeof $.fn.slick === 'function') {
      $(this.element).slick({
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        adaptiveHeight: true
      })
    } else {
      console.error('Slick carousel is not loaded')
    }
  }

  disconnect() {
    if (typeof $.fn.slick === 'function') {
      $(this.element).slick('unslick')
    }
  }
}
