import { Controller } from '@hotwired/stimulus'
import { utils } from './utils/modal'

export default class extends Controller {
  static values = {
    modalId: String
  }
  openModal() {
    const modalId = this.modalIdValue;
    document.dispatchEvent(new CustomEvent(utils.getOpenEventName({contextId: modalId})));
  }
}
