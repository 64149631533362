import ApplicationController from './application_controller'
import {
  initMap,
  buildRouteAndSetDirections,
  directionRouteWaypointsFromAddresses
} from './utils/google-map'

/*
 * <div data-controller="google-map-itinerary"
 *      data-google-map-itinerary-target="output"
 *      data-google-map-itinerary-address-start-value="<%= @departure_address %>"
 *      data-google-map-itinerary-waypoint-addresses-value="<%= @waypoints.to_json %>"
 *      data-google-map-itinerary-address-end-value="<%= @arrival_address %>">
 * </div>
 */
export default class extends ApplicationController {
  static targets = ['output']
  static values = {
    // To make an itinerary from a classic address (no lng/lat)
    addressStart: String,
    waypointAddresses: Array, // Array of addresses that represent waypoints in an itinerary, do not include departure and arrival address
    addressEnd: String
  }

  connect () {
    const directionsService = new google.maps.DirectionsService()
    const directionsRenderer = new google.maps.DirectionsRenderer()

    const map = initMap(this.outputTarget)

    directionsRenderer.setMap(map)

    buildRouteAndSetDirections(
      directionsService,
      directionsRenderer,
      this.addressStartValue,
      this.addressEndValue,
      directionRouteWaypointsFromAddresses(this.waypointAddressesValue)
    )
  }
}
