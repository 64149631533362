import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['us', 'gbp', 'eur', 'select']

    connect () {
      addEventListener('reveal-unit__refresh', () => (this.refresh()), false)
      dispatchEvent(new Event('reveal-unit__refresh'))
    }

    refresh () {
      this.hiddenUnit()
      this.toggleUnit(this.defaultUnit())
    }

    selectUnit (e) {
      const option = e.target.options[e.target.selectedIndex]
      this.toggleUnit(option.getAttribute('data-currency-code'))
    }

    toggleUnit (unit) {
      this.hiddenUnit()

      switch (unit) {
        case 'gbp':
          return this.showItems(this.gbpTargets)
        case 'eur':
          return this.showItems(this.eurTargets)
        case 'usd':
          return this.showItems(this.usTargets)
      }
    }

    showItems (items) {
      items.forEach((item) => {
        item.hidden = false
      })
    }

    hiddenUnit () {
      if (this.usTargets.length) {
        this.usTargets.forEach((item) => {
          item.hidden = true
        })
      }
      if (this.gbpTargets.length) {
        this.gbpTargets.forEach((item) => {
          item.hidden = true
        })
      }
      if (this.eurTargets.length) {
        this.eurTargets.forEach((item) => {
          item.hidden = true
        })
      }
    }

    defaultUnit () {
      const options = this.selectTarget.options
      const option = options[this.selectTarget.selectedIndex]
      return option.getAttribute('data-currency-code')
    }

    addItem () {
      $('#article').on('cocoon:after-insert', () => {
        $('.gbp').hide()
        $('.eur').hide()
        $('.us').hide()
        const unit = this.defaultUnit()
        if (unit === 'gbp') { $('.gbp').show() }
        if (unit === 'eur') { $('.eur').show() }
        if (unit === 'usd') { $('.us').show() }
      })
    }
}
