import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    document.addEventListener("turbo:before-stream-render", this.#beforeStreamRender)
  }

  disconnect () {
    document.removeEventListener("turbo:before-stream-render")
  }

  #beforeStreamRender (event) {
    const fallbackToDefaultActions = event.detail.render

    event.detail.render = function (streamElement) {
      if (streamElement.action === "update_inputs_values") {
        const name = streamElement.getAttribute('name')
        const value = streamElement.getAttribute('value')

        for (const input of document.querySelectorAll(`input[name=${name}]`)) {
          input.value = value
        }
      } else if (streamElement.action === "remove") {
        var elementToRemove = document.getElementById(streamElement.target)

        if (!elementToRemove) { return }
        var streamExitClass = elementToRemove.dataset.streamExitClass

        if (streamExitClass) {
          elementToRemove.classList.add(streamExitClass)
          elementToRemove.addEventListener("transitionend", function() {
            fallbackToDefaultActions(streamElement)
          })
        }
      } else {
        fallbackToDefaultActions(streamElement)
      }
    }
  }
}
