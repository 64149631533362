import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "output"]
    static values = { match: String }

    toggle() {
        const isVisible = this.inputTarget.value === this.matchValue
        this.outputTargets.forEach(el => el.classList.toggle("hidden", !isVisible))
    }
}