import ApplicationController from './application_controller'

/* TODO: Voir si des usages sont encore trouvé après nettoyage */
export default class extends ApplicationController {
  static targets = [ 'fieldToSubmit' ]

  submitForm () {
    const form = event.currentTarget.closest('form')
    const formData = new FormData(form)

    Rails.ajax({
      url: form.action,
      type: form.method,
      data: formData,
      success: (data) => {
        this.notify({ type: 'success', text: data.message })
        this.fieldToSubmitTarget.style.display = 'none'
      },
      error: (data) => {
        this.dataMissing(data)
      }
    })
  }

  dataMissing (data) {
    if (data) {
      this.notify({
        type: 'error',
        text: data.message
      })
    } else {
      this.notify({
        type: 'error',
        text: {
          en: 'Please try later or contact the support team',
          fr: 'Veuillez réessayer ultérieurement ou contacter le support'
        }[localeFromURL()]
      })
    }
  }

  notify (notification = { type: 'error', text: 'Something went wrong' }) {
    const notificationController = document.querySelector('[data-controller="notification"]')

    let controller = this.application.getControllerForElementAndIdentifier(notificationController, 'notification')
    eval(controller['notify'](notification))
  }
}
