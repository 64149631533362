import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    key: String,
    html: String,
    acceptedHtml: String,
    url: String
  }

  connect() {
    if (!this.#hasConsent()) return

    this.element.firstElementChild.innerHTML = this.acceptedHtmlValue || this.accept();
  }

  accept() {
    this.urlValue ? this.#load() : this.#replace()

    this.#setConsent()
  }

  #replace() {
    this.element.innerHTML = this.htmlValue
    this.#executeScriptElements()
  }

  #load() {
    fetch(this.urlValue)
      .then(response => response.text())
      .then(html => {
        this.element.innerHTML = html
        this.#executeScriptElements()
      })
      .catch((err) => console.error(err))
  }

  #consent() {
    return JSON.parse(window.localStorage.getItem('consent') || "{}")
  }

  #hasConsent() {
    let consent = this.#consent()
    return consent[this.keyValue] === true
  }

  #setConsent() {
    let consent = this.#consent()
    consent[this.keyValue] = true
    window.localStorage.setItem('consent', JSON.stringify(consent))
  }

  #executeScriptElements() {
    const scriptElements = this.element.querySelectorAll("script");

    Array.from(scriptElements).forEach((scriptElement) => {
      const clonedElement = document.createElement("script");

      Array.from(scriptElement.attributes).forEach((attribute) => {
        clonedElement.setAttribute(attribute.name, attribute.value);
      });

      clonedElement.text = scriptElement.text;

      scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
    });
  }
}
