import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['buttonTr', 'buttonTd', 'checkboxReduce']
  static values = { url: String }

  clickTheLink (id) {
    id.path[1].firstElementChild.firstElementChild.click()
  }

  displayWholeEmailBody () {
    this.displayAspecificEmailBody(event.target)
  }

  reduceOrOpenAllEmailBody () {
    const allEmailsBody = document.getElementsByClassName('td_email_body')
    Array.from(allEmailsBody).forEach((element) => this.displayAspecificEmailBody(element, true))
  }

  displayAspecificEmailBody (element, booleanValue = false) {
    var wholeText = element.dataset.info
    var pushEmailId = element.parentNode.id.match(/\d+/g).join('')
    var truncateContent = document.getElementById('truncate_email_body_' + pushEmailId).value
    var booleanContent = document.getElementById('allows_truncate_' + pushEmailId)
    var checkboxChecked = document.getElementById('reduce').checked

    if (booleanValue === true) {
      this.fillValues(checkboxChecked === true, wholeText, truncateContent, element, booleanContent)
    } else {
      this.fillValues(booleanContent.value === 'true', wholeText, truncateContent, element, booleanContent)
    }
  }

  fillValues (booleanTest, wholeText, truncateContent, element, booleanContent) {
    if (booleanTest) {
      element.innerText = wholeText
      booleanContent.value = 'false'
    } else {
      element.innerText = truncateContent
      booleanContent.value = 'true'
    }
  }
}
