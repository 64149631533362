// anchor_list_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]
  static values = {
    activeClass: String,
    inactiveClass: String
  }
  offsetValue = 100
  disableScrollTracking = false
  connect() {
    this.observeSections();
  }

  observeSections() {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7
    }

    const observer = new IntersectionObserver(this.handleIntersection.bind(this), observerOptions);
    const sections = document.body.querySelectorAll('[data-anchor-id]');
    sections.forEach(section => {
      observer.observe(section);
    })
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (!entry.isIntersecting || this.disableScrollTracking) return;
      this.setActiveLink(entry.target.id);
    })
  }

  setActiveLink(id) {
    this.linkTargets.forEach(link => {
      const inactiveClasses = this.inactiveClassValue.split(' ').filter(Boolean);
      const activeClasses = this.activeClassValue.split(' ').filter(Boolean);

      if (inactiveClasses.length === 0 || activeClasses.length === 0) return;
      
      link.classList.remove(...activeClasses);
      link.classList.add(...inactiveClasses);

      if (link.getAttribute('href') === `#${id}`) {
        link.classList.remove(...inactiveClasses);
        link.classList.add(...activeClasses);
      }
    });
  }

  handleLinkClick(event) {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href').slice(1);
    const targetSection = document.getElementById(targetId);
    if (targetSection) {
      const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY - this.offsetValue;
      this.disableScrollTracking = true;
      
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });

      setTimeout(() => {
        this.disableScrollTracking = false;
      }, 600);

      history.pushState(null, '', `#${targetId}`);
      
      this.setActiveLink(targetId);
    }
  }
}