import ApplicationController from './application_controller'

export default class ReplaceUrlController extends ApplicationController {
    static values = { url: String, from: String }

  connect () {
    if (this.hasFromValue) {
      window.location.replace(this.urlValue)
    }
  }
}
