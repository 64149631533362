
/**
 * OBJECTIVE: This Stimulus controller has got 2 features (TODO: Split this into 2 different controllers)
 * 1. Fill the billing and picking addresses informations according the organization selected or the order ID => getOrganizationId
 * 2. Fill the picking address informations with a raw address passed as a parameter => autocompletePickingAddress
 *
 */
export default {
  getOrganizationId (event) {
    // Retrieve the organization ID
    const organizationId = this.hiddenTarget.value
    const orderId = this.hiddenTarget.dataset.orderId
    const eventTarget = event.target.dataset
    const ownerId = eventTarget && eventTarget.source === 'organization' ? this.hiddenTarget.value :  this.ownerTarget.value

    if (this.hasPickingAddressesTarget) {
      this.pickingAddressesTarget.innerHTML = ''
    }
    this.inputTarget.hidden = true
    this.removeAddressValues()
    if (organizationId !== null && organizationId !== '') {
      const url = new URL(this.urlValue)
      const params = { id: organizationId, order_id: orderId, owner_id: ownerId }
      url.search = new URLSearchParams(params).toString()

      fetch(url)
        .then((response) => response.json())
        .then(({ pickingAddress, billingAddress }) => {
          let allAddresses = pickingAddress.allAddresses
          let addresses = pickingAddress.addresses

          this.fillAddressValues(pickingAddress, 'picking')
          if (Object.keys(billingAddress).length !== 0) {
            this.buttonTarget.style.display = 'block'
            this.fillAddressValues(billingAddress, 'billing')
          } else {
            this.buttonTarget.style.display = 'none'
          }
          if (allAddresses.length >= 1) {
            this.setInputReadOnly(true)
            this.inputTarget.hidden = true
          } else {
            this.setInputReadOnly(false)
            this.inputTarget.hidden = false
            this.pickingRawAddressTarget.readOnly = true
          }
          allAddresses.forEach(function (address) {
            $('#picking_addresses').append(address)
          })
          $('#picking_addresses').append(
            `<option value='other_address'>${this.otherValue}</option>`
          )

          $("#picking_addresses option").each(function() {
            $(this).siblings('[value="'+ this.value +'"]').remove();
          })
          
          if (orderId) {
            if (!addresses.includes(this.inputTarget.value)) {
              this.inputTarget.hidden = false
              this.pickingAddressesTarget.value = 'other_address'
              this.setInputReadOnly(true)
            }
          }
        })
    }
  },

  autocompletePickingAddress (e) {
    var option = e.target.options[e.target.selectedIndex]
    if (option.value === 'other_address') {
      this.removeAddressValues()
      this.setInputReadOnly(false)
      this.inputTarget.hidden = false
      this.pickingRawAddressTarget.readOnly = true
    } else {
      this.inputTarget.hidden = true
      this.pickingRawAddressTarget.value = option.value
      this.inputTarget.value = option.value
      this.setInputReadOnly(true)
      const url = new URL(this.urlDecomposeAddressValue)
      const params = { id: this.hiddenTarget.value, address_id: option.value, owner_id: this.ownerTarget.value }
      url.search = new URLSearchParams(params).toString()
      fetch(url)
        .then((response) => response.json())
        .then(({ pickingAddress, billingAddress }) => {
          this.fillAddressValues(pickingAddress, 'picking')
        })
    }
  },

  setInputReadOnly (value) {
    this.pickingRawAddressTarget.readOnly = value
    this.companyNameTarget.readOnly = value
    this.contactPhoneTarget.readOnly = value
    this.streetNumberTarget.readOnly = value
    this.routeTarget.readOnly = value
    this.localityTarget.readOnly = value
    this.stateTarget.readOnly = value
    this.countryTarget.readOnly = value
    this.postalCodeTarget.readOnly = value
    this.countryCodeTarget.readOnly = value
    this.address2Target.readOnly = value
  },

  fillAddressValues (address, category) {
    [
      'companyName',
      'contactPhone',
      'route',
      'state',
      'country',
      'postalCode',
      'countryCode',
      'address2',
      'pickingRawAddress',
      'billingRawAddress',
      'billingOtherInformation'
    ].forEach((element) => {
      this.copyField(
        this[`${element}Targets`],
        address[`${element}`],
        category
      )
    })

    this.copyField(this.streetNumberTargets, address.number, category)
    this.copyField(this.localityTargets, address.city, category)

    this.copyField(
      this.pickingOtherInformationTargets,
      address.otherInformation,
      category
    )
    this.copyField(
      this.billingOtherInformationTargets,
      address.billingOtherInformation,
      category
    )
    if (category === 'picking') {
      this.inputTarget.value = address.pickingAddress
      this.hiddenPostalCodeTarget.value = address.postalCode
    }
  },

  copyField (targets, value, category) {
    targets.forEach((target) => this.setTargetValue(target, value, category))
  },

  setTargetValue (target, value, category) {
    if (value !== undefined && target.dataset.role === category && target.value !== value) {
      target.value = value
      target.dispatchEvent(new Event('change'))
    }
  }
}
