import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['type', 'value']

  connect () {
    this.setType()
  }

  setType () {
    const selection = this.typeTarget.querySelector('select').value
    const target = this.valueTargets.find(
      element => element.attributes['data-type'].value === selection
    )

    this.valueTargets.map(
      element => {
        element.setAttribute('style', 'display: none')
        element.querySelector('select').setAttribute('disabled', 'disabled')
      }
    )

    target?.removeAttribute('style')
    target?.querySelector('select').removeAttribute('disabled')
  }
}
