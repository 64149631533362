import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['label']

  display (e) {
    const filenames = this.filenames(e.target.files)
    this.labelTarget.innerHTML = filenames
  }

  displayFromClass (e) {
    const targetClassName = e.target.getAttribute('data-preview-target-class')

    if (!targetClassName) {
      console.error(
        'Missing `preview_target_class` attribute. If this error is displayed' +
        'from a tailwind page, maybe you need to use `tw_form_with` form helper' +
        ' with `f.file_field` and `f.file_preview`'
      )
      return
    }

    const elems = document.getElementsByClassName(targetClassName)

    for (let elem of elems) {
      elem.hasChildNodes() && elem.replaceChildren()

      for (let file of e.target.files) {
        let span = document.createElement('span')
        span.classList.add('badge-primary')
        span.appendChild(document.createTextNode(file.name))

        elem.appendChild(span)
      }
    }
  }

  filenames (files) {
    let filenames = ''

    Array.from(files).forEach(file => {
      if (filenames === '') {
        filenames = file.name
      } else {
        filenames = filenames + ', ' + file.name
      }
    })

    return filenames
  }
}
