import ApplicationController from './application_controller'

/**
 * Values:
 *  urlParam: The custom parameter to add in the URL for the search/filter
 *
 * EXAMPLE:
 *
 * <%= select_tag 'User',
       options_from_collection_for_select(User.all, 'id', 'name', params[:user_id]),
       data: {
        controller: 'search-selected-option',
        'search-selected-option-url-param-value': 'user_id',
        action: 'search-selected-option#search'
       }
  %>
 */

export default class extends ApplicationController {
  static values = {
    urlParam: String
  }
  static targets = ['checkbox']

  search (e) {
    const params = new URLSearchParams(window.location.search)
    let value = e.currentTarget.value
    params.set(this.urlParamValue, value)
    window.location.replace(window.location.pathname + `?${params.toString()}`)
  }
}
