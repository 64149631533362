import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['checkbox', 'destroyField']

  addDestroyAttribute() {
    const action = this.element.dataset.action
    const taggedAsDestroy = this.destroyFieldTarget.value === 'true'
    const checked = this.checkboxTarget.checked

    if (action !== 'edit') return

    if (!checked && !taggedAsDestroy) {
      this.destroyFieldTarget.value = 'true'
    } else if (checked && taggedAsDestroy) {
      this.destroyFieldTarget.value = 'false'
    }
  }
}
