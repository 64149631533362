import ApplicationController from './application_controller'

/*
 * "checkbox-select-all" - A Stimulus controller for managing recursive checkbox lists.
 *
 * ONE LEVEL EXAMPLE:
 *
 * <ul>
 *   <li data-controller="checkbox-select-all">
 *     <label>
 *       <input type="checkbox" data-checkbox-select-all-target="checkboxAll" />
 *       <span>Select all</span>
 *     </label>
 *     <ul data-checkbox-select-all-target="list">
 *       <li><label><input type="checkbox" /><span>Item</span></label></li>
 *       <li><label><input type="checkbox" /><span>Item</span></label></li>
 *       <!-- ... -->
 *     </ul>
 *   </li>
 * </ul>
 *
 *
 * ADVANCED EXAMPLE (WITH SEVERAL LEVELS):
 *
 * <ul>
 *   <li data-controller="checkbox-select-all">
 *     <label>
 *       <input type="checkbox" data-checkbox-select-all-target="checkboxAll" />
 *       <span>Select all</span>
 *     </label>
 *     <ul data-checkbox-select-all-target="list">
 *       <li data-controller="checkbox-select-all">
 *         <label>
 *           <input type="checkbox" data-checkbox-select-all-target="checkboxAll" />
 *           <span>Another select all</span>
 *         </label>
 *         <ul data-checkbox-select-all-target="list">
 *           <li><label><input type="checkbox" /><span>Item</span></label></li>
 *           <li><label><input type="checkbox" /><span>Item</span></label></li>
 *           <!-- ... -->
 *         </ul>
 *       </li>
 *       <!-- ... -->
 *     </ul>
 *   </li>
 * </ul>
 */
export default class extends ApplicationController {
  static targets = [ 'checkboxAll', 'list' ]

  connect () {
    this.checkboxAllTarget.addEventListener('click', (event) => this.parentCheckboxChanged(event))

    this.checkboxes.forEach(function (checkbox) {
      checkbox.addEventListener('change', (event) => this.childCheckboxChanged(event))
    }, this)
  }

  childCheckboxChanged (event) {
    const checkboxesCount = this.checkboxes.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount

    this.checkboxAllTarget.dispatchEvent(new Event('change'))
  }

  parentCheckboxChanged () {
    const status = this.checkboxAllTarget.checked

    this.checkboxes.forEach(function (checkbox) {
      checkbox.indeterminate = false
      checkbox.checked = status
    })
  }

  get checked () {
    return this.checkboxes.filter(checkbox => checkbox.checked)
  }

  get checkboxes () {
    var checkboxList = []
    this.listTargets.forEach(function (list) {
      list.querySelectorAll('input[type=checkbox]').forEach(function (checkbox) {
        checkboxList.push(checkbox)
      })
    })
    return checkboxList
  }
}
