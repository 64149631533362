import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['link', 'pane']
  static values= {
    btnEnabledClass: String,
    btnDisabledClass: String,
    paneDisabledClass: String,
  }

  select () {
    const subject = event.currentTarget.dataset.subject

    this.linkTargets.forEach((elem) => {
      if (elem.dataset.subject === subject) {
        elem.classList.remove(this.btnDisabledClassValue)
        elem.classList.add('active', this.btnEnabledClassValue)
      }
      else {
        elem.classList.remove('active', this.btnEnabledClassValue)
        elem.classList.add(this.btnDisabledClassValue)
      }
    })
    this.paneTargets.forEach((elem) => {
      if (elem.dataset.subject === subject) {
        elem.classList.add('active')
        elem.classList.remove(this.paneDisabledClassValue)
      }
      else {
        elem.classList.remove('active')
        elem.classList.add('hidden')
      }
    })
  };
}
