import _ from 'lodash'

function formatPrice (value) {
  return parseFloat(value).toFixed(2).replace('.', ',') + ' €'
}

export default function setNewPriceDetails (orderDetails) {
  setNewPriceDetails(orderDetails)

  function setNewPriceDetails (orderDetails) {
    var orderDetailsCamelised = _.mapKeys(orderDetails, (value, key) => _.camelCase(key))

    for (let [option, value] of Object.entries(orderDetailsCamelised)) {
      if (option === 'optionsStates') {
        Object.entries(value).forEach(function (item) {
          let elem = document.getElementById('option_' + item[0])
          if (elem) {
            if (item[1] === true) {
              elem.classList.remove('d-none')
            } else {
              elem.classList.add('d-none')
            }
          }
        })
      } else if (option === 'priceWithoutVat') {
        let elem = document.getElementById('price_without_vat')
        elem.innerHTML = formatPrice(value)
      } else if (option === 'vatPrice') {
        let elem = document.getElementById('vat_price')
        elem.innerHTML = formatPrice(value)
      } else if (option === 'addressesIds') {
        document.querySelector('[data-category="delivery"]').value = value.delivery === undefined ? '' : value.delivery
        document.querySelector('[data-category="billing"]').value = value.billing === undefined ? '' : value.billing
      } else if (option === 'itemsCount')  {
        document.getElementById('itemsCount').innerHTML = value
      }
      else {
        let elem = document.getElementById(option)
        if (elem) {
          if (option === 'value') {
            let elemContainer = document.getElementById('items-values-container')
            if (value) {
              elemContainer.classList.remove('d-none')
              elemContainer.classList.add('d-flex')
            } else {
              elemContainer.classList.remove('d-flex')
              elemContainer.classList.add('d-none')
            }
          }

          if (['price', 'transportPrice', 'value'].includes(option)) {
            value = formatPrice(value)
          }

          elem.innerHTML = value
        }
      }
    }
  }
}
