import ApplicationController from './application_controller'

/**
 * TARGETS:
 * input: The inputs that have to be completed or checked to submit the form
 * button: The button that will be disabled till the inputs are filled
 *
 * EXAMPLE:
 * <%= form_with model: User.new, data: { controller: 'inputs-required' } do |form| %>
 * <%= f.input :email, required: true, input_html: { data: { 'inputs-required-target': 'input', action: 'input->inputs-required#validate' }}
 * <%= f.submit 'Submit', class: 'btn', data: { 'inputs-required-target': 'button'} %>
 * <% end %>
 */

export default class extends ApplicationController {
  static targets = ['mainInput', 'dependentInput']
  static values = { dependent: String }
  
  connect () {
    if (this.hasdependentInputTarget && this.hasmainInputTarget) {
        this.mainInputTargets.forEach(input => {
            input.required = false
        })
        this.dependentInputTargets.forEach(input => {
            input.required = false
        })
    }
  }

  updateDependentInputValidation () {
    if (this.hasMainInputTarget && this.hasDependentInputTarget) {
        this.getAllDependentValues().forEach((dependentValue) => {
            let mainInputDependents = this.mainInputTargets.filter(input => { return input.dataset.dependent === dependentValue })
            let dependentInputDependents = this.dependentInputTargets.filter(input => { return input.dataset.dependent === dependentValue })
            let dependentMerged = mainInputDependents.map((mainInput, index) => [mainInput, dependentInputDependents[index]]);

            dependentMerged.forEach(merged => {
              merged[1].required = (merged[0].value !== '')
            })
        })
    }
  }

  getAllDependentValues () {
    return [...new Set(Array.from(document.querySelectorAll('[data-dependent]')).map((dependent) => {
        return dependent.dataset.dependent 
    }))]
  }
}
