import ApplicationController from './application_controller'
import { localeFromURL } from '../components/utilities'

export default class extends ApplicationController {
  static targets = ['packingCreateForm', 'packingEditForm', 'itemEditForm', 'packingTree', 'timeInput']
  //                                SHOW PACKING FORM                               //

  togglePackingNode (event) {
    const packingId = event.currentTarget.dataset.packingId

    const selectors = `.js-caret-container[data-packing-id="${packingId}"], [data-packing-node-id="${packingId}"]`
    document.querySelectorAll(selectors).forEach((node) => node.classList.toggle('d-none'))
  }

  openPackingNode (event) {
    const packingId = event.currentTarget.dataset.packingId
    const carets = document.querySelectorAll(`.js-caret-container[data-packing-id="${packingId}"]`)

    carets.forEach((node) => node.dataset.caretType === 'close' ? node.classList.remove('d-none') : node.classList.add('d-none'))
    document.querySelector(`[data-packing-node-id="${packingId}"]`).classList.remove('d-none')
  }

  //                                SHOW FORM                               //

  showPackingCreateForm () {
    const orderId = this.element.dataset.orderId
    const parentIdParams = `&parent_id=${event.currentTarget.dataset.packingId}`
    const url = window.location.origin + `/${localeFromURL()}/packings/new?order_id=${orderId}${event.currentTarget.dataset.packingId ? parentIdParams : null}`
    fetch(url)
      .then(response => response.json())
      .then(data => { this.showForm(data, 'packingCreateForm') })
  }

  showPackingEditForm (event) {
    const url = window.location.origin + `/${localeFromURL()}/packings/${event.currentTarget.dataset.packingId}/edit`
    fetch(url)
      .then(response => response.json())
      .then(data => { this.showForm(data, 'packingEditForm') })
  }

  showItemEditForm (event) {
    const url = window.location.href.replace('packings', 'items') + `/${event.currentTarget.dataset.id}/edit`
    fetch(url)
      .then(response => response.json())
      .then(data => { this.showForm(data, 'itemEditForm') })
  }

  showForm (data, target) {
    ['packingCreateForm', 'packingEditForm', 'itemEditForm'].forEach((form) => {
      if (form === target) {
        const body = this[`${form}Target`].querySelector('.card-body')
        body.innerHTML = ''
        body.insertAdjacentHTML('beforeend', data['form'])
        this[`${form}Target`].classList.remove('d-none')
      } else {
        this[`${form}Target`].classList.add('d-none')
      }
    })
  }

  hideForm (event) {
    [this.packingCreateFormTarget, this.packingEditFormTarget, this.itemEditFormTarget].forEach((element) => {
      if (element.contains(event.currentTarget)) element.classList.add('d-none')
    })
  }

  //                                SUBMIT PACKING FORM CHANGE                              //

  deletePacking (event) {
    if (confirm(event.currentTarget.dataset.confirmDestroy)) {
      const url = window.location.origin + `/${localeFromURL()}/packings/${event.currentTarget.dataset.packingId}`
      const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value
      fetch(url, {
        method: 'DELETE',
        headers: { 'X-CSRF-Token': csrfToken }
      })
        .then(response => response.json())
        .then(data => {
          this.refreshPage(data)
        })
    }
  }

  // packing and item
  update () {
    event.preventDefault()
    event.stopImmediatePropagation()

    const form = event.currentTarget.closest('form')
    const formData = new FormData(form)

    Rails.ajax({
      url: form.action,
      type: form.method,
      data: formData,
      success: (data) => {
        this.refreshPage(JSON.parse(data))
      },
      error: (data) => {
        if (data.message) {
          this.notify({ type: 'error', text: data.message })
        } else {
          this.notify({
            type: 'error',
            text: {
              en: 'Please try later or contact the support team',
              fr: 'Veuillez réessayer ultérieurement ou contacter le support'
            }[localeFromURL()]
          })
        }
      }
    })
  }

  blobToFile (theBlob, fileName) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['blobToFile'](theBlob, fileName))

    return blobFile
  }

  resizePhotos (settings) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = eval(controller['resizePhotos'](settings))

    return blobFile
  }

  async addToFormData (formData, array, requestValue) {
    const upload = document.querySelector('[data-controller="resize-uploaded-files"]')
    let controller = this.application.getControllerForElementAndIdentifier(upload, 'resize-uploaded-files')
    const blobFile = await eval(controller['addToFormData'](formData, array, requestValue))

    return blobFile
  }

  async finalizePacking () {
    this.uploads = this.retrieveUploads()
    const url = window.location.origin + `/${localeFromURL()}/orders/${this.element.dataset.orderId}/packing_confirms`
    var formData = new FormData()

    formData.append('order[status]', 'packed')
    formData.append('order[packed_at]', new Date())
    formData.append('order[packing_elapsed_time]', this.timeInputTarget.value)
    formData = await this.addToFormData(formData, this.uploads, 'order[packing_uploads][]')

    Rails.ajax({
      url: url,
      type: 'POST',
      data: formData,
      success: (data) => { window.location.reload() },
      error: (data) => {
        if (data.message) {
          this.notify({ type: 'error', text: data.message })
        } else {
          this.notify({
            type: 'error',
            text: {
              en: 'Please try later or contact the support team',
              fr: 'Veuillez réessayer ultérieurement ou contacter le support'
            }[localeFromURL()]
          })
        }
      }
    })
  }

  //                                MISCELLANIOUS                              //

  refreshPage (data) {
    ['packingCreateForm', 'packingEditForm', 'itemEditForm'].forEach((form) => {
      this[`${form}Target`].classList.add('d-none')
    })
    this.packingTreeTarget.innerHTML = ''
    this.packingTreeTarget.insertAdjacentHTML('beforeend', data['packing_tree'])
    this.notify({ type: 'success', text: data['message'] })
    document.getElementById('actualPackedWeight').innerHTML = data['actual_packed_weight']
  }

  notify (notification = { type: 'error', text: 'Something went wrong' }) {
    const notificationController = document.querySelector('[data-controller="notification"]')
    let controller = this.application.getControllerForElementAndIdentifier(notificationController, 'notification')
    eval(controller['notify'](notification))
  }

  retrieveUploads () {
    const uploadPicture = document.querySelector('[data-controller="packing upload-picture delete-image reveal"]')
    let controller = this.application.getControllerForElementAndIdentifier(uploadPicture, 'upload-picture')
    const uploads = eval(controller['retrievePictures']())

    return uploads
  }

  submit () {
    event.currentTarget.closest('form').submit()
  }
}
