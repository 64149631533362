import ApplicationController from './application_controller'

/*
 * Values:
 *   name: Cookie name.
 *   dataBoolean: Cookie boolean value (true or false).
 *   validDay (optional): The validity period (in days) of the cookie since its creation or last modification.
 *                        If null or not provided, the cookie is valid only for the session.
 *
 *
 * EXAMPLE:
 *
 * <button data-controller="cookie"
 *         data-cookie-name-value="<%= admin_sidebar_cookie_key %>"
 *         data-cookie-data-boolean-value="<%= admin_sidebar_is_open? %>"
 *         data-cookie-valid-day-value="365"
 *         data-action="click->cookie#toggleBoolean">
 *   <%= legacy_svg_tag 'icons/fa/bars-solid' %>
 * </button>
 *
 * Note: You can access a cookie through the getCookie method inside this controller or more simply in ruby and outside
 *       this controller with <%= cookies[:cookie_name] %>
 *
 *       Examples:
 *           <div class="admin-sidebar <%= 'open' if truthy? cookies[:admin_sidebar_open] %>">
 *           <div class="admin-sidebar <%= 'open' if admin_sidebar_is_open? %>">
 */
export default class extends ApplicationController {
  static values = { name: String, dataBoolean: Boolean, validDay: Number }

  toggleBoolean () {
    this.dataBooleanValue = !this.dataBooleanValue
    this.setCookie()
  }

  // private

  connect () {
    if (!this.getCookie(this.nameValue)) {
      this.setCookie()
    }
  }

  setCookie () {
    document.cookie = this.nameValue + '=' + this.dataBooleanValue + ';path=/;expires=' + this.stringDateForCookie
  }

  getCookie (name) {
    const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
    return v ? v[2] : null
  }

  get stringDateForCookie () {
    if (!this.validDayValue) { return 'Session' }

    const date = new Date()
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * this.validDayValue)
    return date.toString()
  }
}
