import ApplicationController from './application_controller'

/*
 * EXAMPLE with css class:
 *
 * <button data-controller="reveal"
 *         data-reveal-show-class="show"
 *         data-action="reveal#toggle"
 *         data-css-selector=".admin-sidebar">
 *   ...
 * </button>
 *
 * NOTE : The "data-reveal-show-class" attribute is optional.
 *
 * OR another EXAMPLE with targets:
 *
 * <div data-controller="reveal" data-reveal-show-class="show">
 *   <button data-action="reveal#toggle">
 *     ...
 *   </button>
 *   <div data-reveal-target="toggle">
 *     ...
 *   </div>
 * </div>
 *
 */
export default class extends ApplicationController {
  static classes = [ 'show' ]
  static targets = [ 'toggle', 'autoHide' ]

  connect () {
    this.showCssClass = this.hasShowClass ? this.showClass : 'show'
  }

  toggle (event) {
    const self = this
    const cssSelector = event.currentTarget.getAttribute('data-css-selector')
    const items = Array.from(document.querySelectorAll(cssSelector))

    if (this.autoHideTargets) {
      this.autoHideTargets.forEach(item => {
        if (!items.includes(item)) {
          item.classList.add('hidden')
        }
      })
    }

    if (this.hasToggleTarget) {
      this.toggleTargets.forEach(item => {
        item.classList.toggle(self.showCssClass)
      })
    } else {
      items.forEach(item => item.classList.toggle(self.showCssClass))
    }
  }
}
