import ApplicationController from './application_controller'

export default class extends ApplicationController {
    connect() {
        this.checkVideoPosition = this.checkVideoPosition.bind(this);
        window.addEventListener('scroll', this.checkVideoPosition);
        this.checkVideoPosition();
    }

    disconnect() {
        window.removeEventListener('scroll', this.checkVideoPosition);
    }

    checkVideoPosition() {
        const videoElement = this.element;
        if (!videoElement) return;

        const videoBottomPosition = videoElement.getBoundingClientRect().top;
        const threshold = -500; // 500px du bas de l'écran

        if (window.innerHeight - videoBottomPosition <= threshold) {
            videoElement.removeAttribute('autoplay');
            videoElement.pause();
        } else {
            videoElement.setAttribute('preload', 'true');
            videoElement.setAttribute('autoplay', 'true');
            videoElement.play();
        }
    }
}
