import ApplicationController from './application_controller'

/*
 * "filter-by-date-interval" - A Stimulus controller for filter items by dates (from => to).
 *
 * Example:
 *
 * <div data-controller="filter-by-date-interval">
 *   <div>
 *     <div>
 *       <%= label_tag("min_date", 'From') %>
 *       <%= date_field_tag("min_date", Date.tomorrow.strftime('%Y-%m-%d'), {
 *         'data-filter-by-date-interval-target': 'from',
 *         'data-action': 'change->filter-by-date-interval#refresh' }) %>
 *     </div>
 *     <div>
 *       <%= label_tag("max_date", 'To') %>
 *       <%= date_field_tag("max_date", Date.tomorrow.strftime('%Y-%m-%d'), {
 *         'data-filter-by-date-interval-target': 'to',
 *         'data-action': 'change->filter-by-date-interval#refresh' }) %>
 *     </div>
 *   </div>
 *
 *   <ul>
 *     <li data-date="25-02-2021" data-filter-by-date-interval-target="item">Lorem ipsum</li>
 *     <li data-date="29-04-2021" data-filter-by-date-interval-target="item">Lorem ipsum</li>
 *     <!-- ... -->
 *   </ul>
 * </div>
 */
export default class extends ApplicationController {
  static targets = [ 'from', 'to', 'item' ]

  connect () {
    this.separator = '-'
    this.refresh()
  }

  refresh () {
    if (!this.fromTarget || !this.toTarget || !this.itemTargets.length) return

    var [fromDay, fromMonth, fromYear] = this.reverseDateFormat(this.fromTarget.value)
    var [toDay, toMonth, toYear] = this.reverseDateFormat(this.toTarget.value)

    var from = new Date(fromYear, fromMonth - 1, fromDay)
    var to = new Date(toYear, toMonth - 1, toDay)

    this.filter(from, to)
  }

  // private

  filter (from, to) {
    this.itemTargets.forEach(function (item) {
      const dateStr = item.getAttribute('data-date')
      if (!dateStr) return
      var [day, month, year] = dateStr.split(this.separator)
      var date = new Date(year, month - 1, day)
      item.style.display = (date >= from && date <= to) ? 'initial' : 'none'
    }, this)
  }

  // Switch and split date format, eg: yyyy-mm-dd to [dd, mm, yyyy]
  reverseDateFormat (str, separator = this.separator) {
    return str.split(separator).reverse()
  }
}
