import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'form', 'field', 'link' ]

  static values = {
    type: String, // ex: 'submit', 'click', 'keypress'
    key: String
  }

  connect () {
    if (!this.typeValue || (this.typeValue === 'keypress' && !this.keyValue)) {
      return
    }

    switch (this.typeValue) {
      case 'submit':
        return this.formTarget.addEventListener('submit', (e) => e.preventDefault())
      case 'click':
        return this.linkTarget.addEventListener('click', (e) => e.preventDefault())
      case 'keypress':
        return this.fieldTargets.forEach((target) => {
          target.addEventListener('keydown', e => e.key === this.keyValue && e.preventDefault())
        })
    }
  }
}
