import ApplicationController from './application_controller'

import PickingAddresses from './utils/picking_addresses'

export default class extends ApplicationController {
  static targets = [
    'hidden',
    'owner',
    'input',
    'pickingRawAddress',
    'deliveryRawAddress',
    'pickingAddresses',
    'streetNumber',
    'route',
    'contactPhone',
    'address2',
    'locality',
    'state',
    'country',
    'postalCode',
    'hiddenPostalCode',
    'countryCode',
    'pickingOtherInformation',
    'companyName',
    'billingRawAddress',
    'billingOtherInformation',
    'button'
  ]

  static values = {
    url: String,
    other: String,
    urlDecomposeAddress: String
  }

  getOrganizationId () {
    PickingAddresses.getOrganizationId.apply(this, arguments)
  }

  autocompletePickingAddress (e) {
    PickingAddresses.autocompletePickingAddress.apply(this, arguments)
  }

  setInputReadOnly (value) {
    PickingAddresses.setInputReadOnly.apply(this, arguments)
  }

  removeAddressValues () {
    this.pickingRawAddressTarget.value = ''
    this.inputTarget.value = ''
    this.companyNameTarget.value = ''
    this.contactPhoneTarget.value = ''
    this.streetNumberTarget.value = ''
    this.routeTarget.value = ''
    this.localityTarget.value = ''
    this.stateTarget.value = ''
    this.countryTarget.value = ''
    this.postalCodeTarget.value = ''
    this.address2Target.value = ''
    this.hiddenPostalCodeTarget.value = ''
    this.countryCodeTarget.value = ''
    this.pickingOtherInformationTarget.value = ''
  }

  fillAddressValues (companyName, contactPhone, number, route, city, state, country, postalCode, countryCode, address2, pickingRawAddress, pickingAddress, otherInformation) {
    PickingAddresses.fillAddressValues.apply(this, arguments)
  }

  copyField (targets, value, category) {
    PickingAddresses.copyField.apply(this, arguments)
  }

  setTargetValue (targets, value, category) {
    PickingAddresses.setTargetValue.apply(this, arguments)
  }
}
