import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['shortText', 'longText', 'moreButton', 'lessButton']

  connect () {
    this.showLess()
  }

  showMore () {
    this.shortTextTarget.hidden = true
    this.moreButtonTarget.hidden = true
    this.longTextTarget.hidden = false
    this.lessButtonTarget.hidden = false
  }

  showLess () {
    this.shortTextTarget.hidden = false
    this.moreButtonTarget.hidden = false
    this.longTextTarget.hidden = true
    this.lessButtonTarget.hidden = true
  }
}
