import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['refresh']
  static values = { refreshInterval: Number }

  connect() {
    if (this.hasRefreshTarget) {
      this.startRefreshing()
    }
  }

  startRefreshing() {
    setInterval(() => {
      this.load()
    }, this.refreshIntervalValue)
  }

  load() {
    window.location.reload();
  }
}
