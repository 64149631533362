import ApplicationController from './application_controller'
import printJS from 'print-js'

export default class extends ApplicationController {
  static targets = [ 'hidden' ]

  printImage () {
    const imageLink = this.hiddenTarget.value
    printJS({
      printable: imageLink,
      type: 'image',
      imageStyle: 'width:100%;position:absolute;top:50px;margin:0px0px0px0px;right:6px' })
  }

  printPdf () {
    const imageLink = this.hiddenTarget.value
    printJS({
      printable: imageLink,
      type: 'pdf',
      imageStyle: 'width:100%;position:absolute;top:50px;margin:0px0px0px0px;right:6px' })
  }
}
