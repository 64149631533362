import ApplicationController from './application_controller'

/**
 * TARGETS:
 * input: The inputs that have to be completed or checked to submit the form
 * button: The button that will be disabled till the inputs are filled
 *
 * EXAMPLE:
 * <%= form_with model: User.new, data: { controller: 'inputs-required' } do |form| %>
 * <%= f.input :email, required: true, input_html: { data: { 'inputs-required-target': 'input', action: 'input->inputs-required#validate' }}
 * <%= f.submit 'Submit', class: 'btn', data: { 'inputs-required-target': 'button'} %>
 * <% end %>
 */

export default class extends ApplicationController {
  static targets = [ 'input', 'button', 'link' ]
  connect () {
    this.buttonTarget.disabled = true

    if (this.hasLinkTarget) {
      this.linkTargets.forEach(item => item.classList.add('disabled'))
    }
  }
  validate () {
    let hasBlankValue = false

    this.inputTargets.forEach((element) => {
      const value = element.value.trim()

      const valueIsNull = [null, ''].includes(value)
      const checkBoxNotChecked = ((element.type === 'checkbox' || element.type === 'radio') && !element.checked)

      if (checkBoxNotChecked || valueIsNull) {
        hasBlankValue = true
      }
    })

    if (this.buttonTarget) {
      this.buttonTargets.forEach(item => { item.disabled = hasBlankValue })
    }
    if (this.hasLinkTarget) {
      this.linkTargets.forEach(item => item.classList.toggle('disabled', hasBlankValue))
    }
  }
}
