import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['toBeCollectedAt', 'selectedValue', 'incidentTagDiv']

  connect() {
    this.toggleIncidentTagDiv()
  }

  autofillDate () {
    if (this.selectedValueTarget.value === 'to_be_collected') {
      this.toBeCollectedAtTarget.valueAsDate = new Date()
    } else {
      this.toBeCollectedAtTarget.value = ''
    }
  }

  toggleIncidentTagDiv () {
    if (this.selectedValueTarget.value === 'collection_incident') {
      this.incidentTagDivTarget.hidden = false
    } else {
      this.incidentTagDivTarget.hidden = true
    }
  }
}
