// https://css-tricks.com/debouncing-throttling-explained-examples/
// https://fullstackheroes.com/tutorials/stimulus/debounce-throttle/
// debouncing: Debounce groups multiple calls into a single one. It enforces a function not to be called until a given period of time has passed, for example, "Execute this function if 1 second has passed without it being called".

// <%= form_with url: insurance_form_url, scope: :lead,
//               data: {  controller: 'debounce',
//                       'debounce-action-value': 'debouncing',
//                       'debounce-target': 'form' },
//               method: :put do |form| %>

// <%= button_tag(id: 'insurance', role: 'switch', 'aria-checked': false,
//                data: { action: 'debounce#requestSubmit' } do %>

import ApplicationController from './application_controller'
let debounce = require('lodash/debounce')

export default class extends ApplicationController {
  static targets = ['form']
  static values = { wait: Number, default: 200 }

  connect () {
    this.requestSubmit = debounce(this.submit, this.waitValue).bind(this)
    this.formTarget.addEventListener('submit-requested', () => { this.submit() })
  }

  requestSubmit (e) {
    e.preventDefault()
    this.formTarget.dispatchEvent(new Event('submit-requested'))
  }

  submit () {
    this.formTarget.requestSubmit()
  }
}
