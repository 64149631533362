import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['elementToHide', 'childrenContainer']

  connect () {
    if (!this.hasElementToHideTarget || !this.hasChildrenContainerTarget) {
      return
    }

    this.childrenContainerTarget.addEventListener(
      'DOMSubtreeModified', (event) => this.hide(event.currentTarget)
    )

    this.childrenContainerTarget.dispatchEvent(new Event('DOMSubtreeModified'))
  }

  hide (htmlElement) {
    if (!htmlElement.childElementCount) {
      this.elementToHideTarget.classList.add('hidden')
    } else {
      this.elementToHideTarget.classList.remove('hidden')
    }
  }
}
