import ApplicationController from './application_controller'

export default class SetNavigatorClassController extends ApplicationController {
  static targets = ['component']
  connect () {
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    
    if (isSafari) {
      this.componentTarget.classList.add('safari')
    }
  }
}
