import ApplicationController from './application_controller'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

export default class extends ApplicationController {
  static targets = ['deleteImage', 'deleteForm']

  deleteAfterPacking () {
    if (confirm(event.currentTarget.dataset.confirmText)) {
      const uploadId = event.currentTarget.dataset.info
      var action = this.deleteFormTarget
      var url = event.currentTarget.dataset.url

      var body = new FormData()

      body.append('upload_id', uploadId)

      body.append('authenticity_token', action.children[0].value)
      body.append('after_submit', 'true')

      Rails.ajax({
        url: url,
        type: 'DELETE',
        data: body,
        success: (data) => { window.location.reload() },
        error: (data) => {
          if (data.message) {
            this.notify({ type: 'error', text: data.message })
          } else {
            this.notify({
              type: 'error',
              text: {
                en: 'Please try later or contact the support team',
                fr: 'Veuillez réessayer ultérieurement ou contacter le support'
              }[localeFromURL()]
            })
          }
        }
      })
    }
  }

  notify (notification = { type: 'error', text: 'Something went wrong' }) {
    const notificationController = document.querySelector('[data-controller="notification"]')
    let controller = this.application.getControllerForElementAndIdentifier(notificationController, 'notification')
    eval(controller['notify'](notification))
  }
}
