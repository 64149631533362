import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = {
    allowedOrigins: String,
    banner: Boolean,
    keys: String
  }

  connect() {
    this.#register()
    this.#notify()
    this.#loadConsentUI()
    this.#updateBannerVisibility()
  }

  save() { this.#saveConsent(checked => checked) }
  accept() { this.#saveConsent(checked => true) }
  reject() { this.#saveConsent(checked => false) }

  #register() {
    if (!this.hasAllowedOriginsValue) return
    let allowedOrigins = this.allowedOriginsValue.split(',')

    window.addEventListener (
      "message",
      (event) => {
        let data = this.#consentData()

        if (!allowedOrigins.includes(event.origin + '/')) return
        if (!event.data.date || (data.date && new Date(event.data.date) <= new Date(data.date))) return

        this.#storeConsent(event.data)
        window.location.reload()
      },
      false
    )
  }

  #notify() {
    if (window.top === window.self) return

    console.log('notify')
    window.parent.postMessage(this.#consentData(), document.referrer)
  }

  #loadConsentUI() {
    let data = this.#consentData()

    for (const key of Object.keys(data)) {
      let element = this.element.querySelector(`#${key}`)
      if (!element) { continue }

      element.checked = data[element.id]
    }
  }

  #updateBannerVisibility() {
    if (this.hasBannerValue && this.bannerValue && !this.#hasConsentExpired()) {
      this.element.remove()
    }
  }

  #hasConsentExpired() {
    let data = this.#consentData()

    if (!data || !data.date) { return true }

    let consentDate = new Date(data.date)
    let now = new Date()
    let minDate = new Date(now.setDate(now.getDate() - 30))

    return (consentDate < minDate)
  }

  #saveConsent(callback) {
    let data = this.#blankData()

    for (const box of this.#consentKeys()) {
      data[box.id] = callback(box.checked)
    }

    this.#storeConsent(data)
    this.#close()
  }

  #consentKeys() {
    if (this.hasKeysValue) {
      return this.keysValue.split(',').map(key => ({ id: key, checked: false }))
    } else {
      return this.#boxes()
    }
  }

  #close() {
    window.location.reload()
  }

  #boxes() {
    return this.element.querySelectorAll('input[type=checkbox]')
  }

  #blankData() {
    return { date: new Date() }
  }

  #storeConsent(consent) {
    window.localStorage.setItem('consent', JSON.stringify(consent))
  }

  #consentData () {
    try {
      return JSON.parse(window.localStorage.getItem('consent') || {})
    } catch (error) {
      return {}
    }
  }
}
