import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Copy the values of the input fields that has a data-source corresponding to the data-copy-source of the form, into the output fields.
 * The copy method is called when an specific event is sent to the form.
 *
 * TARGETS:
 * form: The form that will receive the event.
 * input: The fields where the values will be copied FROM.
 * output: The fields where the value will be copied IN.
 *
 * VALUES:
 * event: The name of the event that is sent to the form and that will trigger the copy method.
 *
 * DATA ATTRIBUTES:
 * copy-source: Indicates what will be the source of the copy. For example if it's "data-copy-source='delivery'", then it'll copy the input fields where "data-source" == "delivery".
 * description: Description of the field, it's a way to find the input that corresponds to the output.
 * source: Represents the source of the input. For example an input that has "data-source='delivery'" means that it belongs to the Delivery source.
 *
 * EXAMPLE:
 * Here we're going to copy the value of the input#delivery_address into the input#billing_address when the form receives a "completion_complete" event.
 *
 * <div data-controller="form-copy-fields" data-form-copy-fields-event-value="completion_complete">
 *  <form id="my_form" data-copy-source="delivery">
 *    <input id="delivery_address" data-description="rawAddress" data-form-copy-fields-target="input" data-source="delivery">
 *    <input id="billing_address" data-description="rawAddress" data-form-copy-fields-target="output" data-source="billing">
 *  </form>
 * </div>
 *
 */

export default class extends ApplicationController {
  static targets = [ 'form', 'input', 'output' ]
  static values = { event: String }

  connect () {
    if (this.hasFormTarget) {
      this.formTargets.forEach((formTarget) => {
        formTarget.addEventListener(this.eventValue, () => {
          this.copy(formTarget)
        })
      })
    }
  }

  copy (e) {
    if (this.hasInputTarget && this.hasOutputTarget) {
      this.copyInputIntoOutput(e.closest('form').dataset.copySource)
    }
  }

  copyInputIntoOutput (source) {
    if (source) {
      // Filter inputsTarget to get only the inputs where the data-source is equal to the data-copy-source of the formTarget
      const inputstargets = this.inputTargets.filter(element => { return element.getAttribute('data-source') === source })
      this.copyField(inputstargets)
    }
  }

  copyField (inputTargets) {
    this.outputTargets.forEach(output => {
      // Retrieve the input where the data-description is equal to the output's one
      let inputElement = inputTargets.find(input => { return input.dataset.description === output.dataset.description })
      if (inputElement !== undefined) {
        output.value = inputElement.value
      }
    })
  }
}
