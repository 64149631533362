import ApplicationController from './application_controller'

export default class ActiveAdminModalController extends ApplicationController {
  static values = {
    direction: Number
  }

  connect() {
    this.directionValue = 1;
    this.isDragging = false;
    this.startOrRestartAutoScroll();
  }

  disconnect() {
    clearInterval(this.autoScrollInterval);
  }

  startDrag(e) {
    this.isDragging = true;
    this.startX = e.pageX;
    this.startScroll = this.element.scrollLeft;
    this.element.classList.add('no-snap', 'cursor-grabbing');
    this.element.classList.remove('cursor-grab');

    document.addEventListener('mousemove', this.drag.bind(this), { passive: true });
    document.addEventListener('mouseup', this.stopDrag.bind(this));

    e.preventDefault();
  }

  drag(e) {
    if (!this.isDragging) return;

    const dx = this.startX - e.pageX;
    this.element.scrollLeft = this.startScroll + dx;
  }

  stopDrag() {
    this.isDragging = false;
    this.element.classList.remove('no-snap', 'cursor-grabbing');
    this.element.classList.add('cursor-grab');

    document.removeEventListener('mousemove', this.drag.bind(this));
    document.removeEventListener('mouseup', this.stopDrag.bind(this));

    // Effacer l'intervalle actuel
    clearInterval(this.autoScrollInterval);

    // Après 10 secondes, redémarrez l'auto-scroll
    setTimeout(() => {
      this.startOrRestartAutoScroll();
    }, 8000);
  }

  autoScroll() {
    if (this.isDragging) return;

    const maxScroll = this.element.scrollWidth - this.element.clientWidth;
    if (this.element.scrollLeft >= maxScroll && this.directionValue === 1) {
      this.directionValue = -1;
    } else if (this.element.scrollLeft === 0 && this.directionValue === -1) {
      this.directionValue = 1;
    }

    const scrollAmount = 200 * this.directionValue;
    this.element.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  }

  startOrRestartAutoScroll() {
    clearInterval(this.autoScrollInterval); // effacer l'intervalle existant
    this.autoScrollInterval = setInterval(() => {
      this.autoScroll();
    }, 1000);
  }
}
