import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'input', 'countryCode' ]
  connect () {
    this.loadTel()
  }
  loadTel(){
    let iti = window.intlTelInput(this.inputTarget, {
      initialCountry: "auto",
      geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
          .then(res => res.json())
          .then(data => callback(data.country_code))
          // eslint-disable-next-line standard/no-callback-literal
          .catch(() => callback("fr"));
      },
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.5.6/build/js/utils.js",
    });
    this.countryCodeTarget.value = iti.getSelectedCountryData().iso2
    this.inputTarget.addEventListener('countrychange', () => {
      this.countryCodeTarget.value = null
      this.countryCodeTarget.value = iti.getSelectedCountryData().iso2
    });
  }
}
