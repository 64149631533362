import ApplicationController from './application_controller'

/*
  <div data-controller="spinner" data-spinner-hidden-class-value="d-none">
    <%= render 'shared/loader' %>
    <div data-spinner-target="container" class="d-none">
      <div data-spinner-target="content" data-loader="true">
      </div>
    </div>
  </div>
 */

export default class extends ApplicationController {
  static targets = ['content', 'container', 'loader']
  static values = { hiddenClass: String }
  connect () {
    if (this.contentTarget.dataset.loader === 'true') {
      this.loaderTarget.classList.toggle(this.hiddenClassValue)
      this.containerTarget.classList.toggle(this.hiddenClassValue)
    }
  }
}
