import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['requiredField', 'requiredChecked', 'paymentButton']

  connect () { this.validate() }

  validate () {
    var fields = this.requiredFieldTargets.map(elem => !!elem.value && !elem.classList.contains('is-invalid'))
    var checkboxes = this.requiredCheckedTargets.map(elem => elem.checked)

    this.paymentButtonTargets.forEach((elem) => { elem.disabled = fields.concat(checkboxes).includes(false) })

    const self = this
    setTimeout(function () {
      self.validate()
    }, 1000)
  };
}
