import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'hidden', 'form', 'result', 'dropdownMenu']

  connect() {
    if (this.hasInputTarget) this.inputTarget.focus()
    this.isHidden = true
    this.dropdownMenuTarget.style.display = 'none'
  }

  fetchOperatorOrder() {
    const searchValue = this.inputTarget.value

    if (!searchValue) {
      this.resultTarget.style.display = 'none'
      return
    }

    const url = `/orders/operators/autocomplete?q=${searchValue}`

    fetch(url)
      .then(response => response.json())
      .then(result => this.fillResultHtml(result))
  }

  fillResultHtml(result) {
    this.resultTarget.innerHTML = result.list
    this.resultTarget.style.display = 'block'
  }

  submitFormWithOperatorId(event) {
    this.hiddenTarget.value = event.currentTarget.dataset.userId
    this.inputTarget.value = event.currentTarget.innerText
    this.formTarget.requestSubmit()
  }

  toggleSearchField() {
    this.isHidden ? this.openSearchField() : this.closeSearchField()
  }

  openSearchField() {
    this.dropdownMenuTarget.style.display = 'block'
    this.inputTarget.value = ''
    this.resultTarget.innerHTML = ''
    this.isHidden = false
  }

  closeSearchField() {
    this.dropdownMenuTarget.style.display = 'none'
    this.resultTarget.style.display = 'none'
    this.isHidden = true
  }
}
