export default (url, filename, mimeType) => {
  return window.fetch(url)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new window.File([buf], filename, {
        type: mimeType
      })
    })
}
