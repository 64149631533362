import ApplicationController from './application_controller'
import List from 'list.js'

export default class extends ApplicationController {
  connect () {
    new List('orders-table-wrapper', {
      valueNames: ['identifier', 'reference', 'owner', 'customer', 'invoiced_at', 'paid_at', 'confirmed_at', 'statut', 'retrocommission', 'lead', 'informations', 'goods_description']
    })
  }

  confirmCollection () {
    event.preventDefault()
    const form = event.currentTarget.closest('form')

    Rails.ajax({
      url: form.action,
      type: form.method,
      data: { order: { status: form.querySelector('#order_status').value } },
      dataType: 'json',
      success: (data) => {
        this.updateFrontConfirmCollection(JSON.parse(data))
      }
    })
  }

  updateFrontConfirmCollection (data) {
    const orderRow = document.querySelector(`tr[data-id="${data['id']}"]`)
    orderRow.querySelector('.statut span').innerHTML = data['order_status']
    orderRow.querySelector(`button[data-target="#confirmModal-${data['id']}"]`).classList.add('d-none')
    orderRow.querySelector(`#js-confirm-${data['id']}`).classList.add('d-none')
    orderRow.querySelector('.alert').classList.remove('d-none')
  }
}
