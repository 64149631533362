import ApplicationController from './application_controller'

import { Html5QrcodeScanner } from 'html5-qrcode'

export default class extends ApplicationController {
  static targets = ['video', 'resourceId', 'resourceType']

  connect () {
    this.qrScanner = new Html5QrcodeScanner('reader', { })
    this.qrScanner.render(this.onScanSuccess, this.onScanFailure)
  }

  onScanSuccess(decodedText, decodedResult) {
    var data = JSON.parse(decodedText)
    var form = document.getElementById('scan_form')

    if (typeof(data) === 'number') {
      form.querySelector('#scan_id').value = data
    } else if (typeof(data) === 'object') {
      form.querySelector('#scan_type').value = data.type
      form.querySelector('#scan_id').value = data.id
    }

    form.submit()
  }

  onScanFailure(error) {
    console.log(error)
  }
}
