import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['menu']

    connect() {
        this.hideMenu()
        this.outsideClickListener = this.outsideClickListener.bind(this)
    }

    toggle({ currentTarget: button }) {
        const buttonHeight = button.offsetHeight

        this.resetHang(buttonHeight)

        if (this.menuTarget.classList.contains('hidden')) {
            this.showMenu(buttonHeight)
        } else {
            this.hideMenu()
        }
    }

    showMenu(buttonHeight) {
        this.applyTransitionClasses('enter')
        this.menuTarget.classList.remove('hidden')
        this.hang(buttonHeight)

        requestAnimationFrame(() => {
            this.applyTransitionClasses('enter', true)
        })

        document.addEventListener('click', this.outsideClickListener)
    }

    hideMenu() {
        this.applyTransitionClasses('leave')

        requestAnimationFrame(() => {
            this.menuTarget.classList.add('hidden')
            this.applyTransitionClasses('leave', true)
        })

        document.removeEventListener('click', this.outsideClickListener)
    }

    applyTransitionClasses(type, isTo = false) {
        const fromClass = this.menuTarget.dataset[`transition${type.charAt(0).toUpperCase() + type.slice(1)}From`].split(' ')
        const toClass = this.menuTarget.dataset[`transition${type.charAt(0).toUpperCase() + type.slice(1)}To`].split(' ')

        if (isTo) {
            fromClass.forEach(cls => this.menuTarget.classList.remove(cls))
            toClass.forEach(cls => this.menuTarget.classList.add(cls))
        } else {
            fromClass.forEach(cls => this.menuTarget.classList.add(cls))
            toClass.forEach(cls => this.menuTarget.classList.remove(cls))
        }
    }

    hang(buttonHeight) {
        const menuRect = this.menuTarget.getBoundingClientRect()
        const safetyMargin = 10

        if (menuRect.left < 0 && menuRect.right < window.innerWidth - safetyMargin) {
            this.hangLeft()
        } else if (menuRect.right > window.innerWidth - safetyMargin) {
            this.hangRight()
        } else {
            this.hangLeft()
        }

        if (menuRect.bottom > window.innerHeight - safetyMargin) {
            this.hangBottom(buttonHeight)
        } else if (menuRect.top < 0 - safetyMargin) {
            this.hangTop(buttonHeight)
        }
    }

    hangLeft() {
        this.menuTarget.style.left = '0px'
        this.menuTarget.style.right = null
    }

    hangRight() {
        this.menuTarget.style.right = '1px'
        this.menuTarget.style.left = null
    }

    hangBottom(buttonHeight) {
        this.menuTarget.style.top = null
        this.menuTarget.style.bottom = `${buttonHeight + 16}px`
    }

    hangTop(buttonHeight) {
        this.menuTarget.style.bottom = null
        this.menuTarget.style.top = `${buttonHeight + 16}px`
    }

    resetHang(buttonHeight) {
        this.hangTop(buttonHeight)
        this.hangLeft(buttonHeight)
    }

    outsideClickListener(event) {
        if (!this.element.contains(event.target)) {
            this.hideMenu()
            document.removeEventListener('click', this.outsideClickListener)
        }
    }
}
