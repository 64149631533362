import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Execute an action on an element when a specific event is triggered,
 * either on the document or on the element itself.
 *
 * EXAMPLE 1: Automatically play a video when a 'start-video' event is triggered on the document
 * <div data-controller="action-on-event"
 *      data-action-on-event-event-name-value="start-video"
 *      data-action-on-event-action-name-value="play">
 *  <video data-action-on-event-target="element" controls>
 *    <source src="movie.mp4" type="video/mp4">
 *    Your browser does not support the video tag.
 *  </video>
 * </div>
 *
 * EXAMPLE 2: Display an alert when a button is clicked within the same element
 * <div data-controller="action-on-event"
 *      data-action-on-event-event-name-value="click"
 *      data-action-on-event-action-name-value="alert"
 *      data-action-on-event-event-on-document-value="false">
 *  <button data-action-on-event-target="element">Click me</button>
 * </div>
 */

export default class extends ApplicationController {
  static targets = ['element']
  static values = { 
    eventName: String,
    actionName: String,
    eventOnDocument: { type: Boolean, default: true }
  }

  connect () {
    if (this.eventOnDocumentValue) {
      document.addEventListener(this.eventNameValue, () => this.process())
    } else {
      this.elementTarget.addEventListener(this.eventNameValue, () => this.process())
    }
  }

  process () {
    this.elementTarget[this.actionNameValue]()
  }
}
