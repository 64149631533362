import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Switch class of elements betwen their activeClass and inactiveClass when
 * calling the switch method
 *
 * EXAMPLE:
 * <div data-controller="switch-class">
 *  <%= button_tag(id: 'example', type: 'button', role: 'switch',
      data: {
        action: 'switch-class#switch',
        'switch-class-target': 'element',
        'active-class': 'bg-green-700',
        'inactive-class': 'bg-gray-200'
      },
    class: 'bg-green-700') do %>
      <span data-switch-class-target="element" data-active-class="bg-green-700" data-inactive-class="bg-red-700">Example</span>
    <% end %>
 * </div>
 */

export default class extends ApplicationController {
  static targets = ['element']
  static values = { selector: String }

  switch () {
    let elements = this.elementTargets

    if (this.hasSelectorValue) {
      elements = document.querySelectorAll(this.selectorValue)
    }

    elements.forEach(element => this.#toggleClasses(element))
  }

  #toggleClasses (element) {
    const activeClasses = element.dataset.activeClass?.split(',') || [];
    const inactiveClasses = element.dataset.inactiveClass?.split(',') || [];

    activeClasses.forEach((activeClass) => element.classList.toggle(activeClass));
    inactiveClasses.forEach((inactiveClass) => element.classList.toggle(inactiveClass));
  }
}
