import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Send a message to the host when the app is embedded in an iFrame
 *
 * EXAMPLE:
 * <div data-controller="send-message-to-host" data-send-message-to-host-message-value="Hello World!">
 *  <div data-action="click->send-message-to-host#send">Send message</div>
 * </div>
 */

export default class extends ApplicationController {
  static values = {
    message: String
  }

  send () {
    window.parent.postMessage(this.messageValue, '*')
  }
}
