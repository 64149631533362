import ApplicationController from './application_controller'
let debounce = require('lodash/debounce')

/**
 * GOAL: Click on a button
 */
export default class extends ApplicationController {
  static targets = ['button']
  static values = {
    enableDebounce: { type: Boolean, default: false },
    wait: { type: Number, default: 1000 }
  }

  connect() {
    if (this.enableDebounceValue) {
      this.call = debounce(this.call, this.waitValue).bind(this)
    }
  }

  call() {
    this.buttonTarget.click()
  }
}
