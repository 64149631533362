import ApplicationController from './application_controller'
import {
  centerMapOnMarkers, drawLineBetweenMarkers, addMarkers
} from './utils/google-map'

export default class extends ApplicationController {
  static targets = ['output']
  static values = {
    latLngStart: Object,
    latLngEnd: Object
  }

  #defaultZoom = 4

  connect () {
    const map = new google.maps.Map(this.outputTarget, {
      zoom: this.#defaultZoom
    })

    const markerLatLngList = [
      // TODO: move that into a value with an array of latLng json
      this.latLngStartValue,
      this.latLngEndValue
    ]

    const markers = addMarkers(map, markerLatLngList)
    drawLineBetweenMarkers(map, markers)
    centerMapOnMarkers(map, markers)
  }
}
