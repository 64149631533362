import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'streetNumber', 'route', 'locality', 'state', 'country', 'postalCode',
    'formattedAddress', 'countryCode', 'latitude', 'longitude', 'rawAddress',
    'form'
  ]

  static componentForm = {
    street_number: { field: 'short_name', target: 'streetNumber' },
    route: { field: 'long_name', target: 'route' },
    locality: { field: 'long_name', target: 'locality' },
    postal_town: { field: 'long_name', target: 'locality' },
    administrative_area_level_1: { field: 'short_name', target: 'state' },
    country: { field: 'long_name', target: 'country' },
    postal_code: { field: 'short_name', target: 'postalCode' }
  }

  connect () {
    const eventName = this.data.get('listen-to')
    document.addEventListener(eventName, this.fillInAddress())
  }

  capitalize (str) {
    if (typeof str === 'string') {
      return str.replace(/^\w/, c => c.toUpperCase())
    } else {
      return ''
    }
  };

  fillInAddress () {
    const klass = this.constructor
    const controller = this

    return function (event) {
      const place = event.detail.place
      for (const type in klass.componentForm) {
        const descriptor = klass.componentForm[type]
        if (controller[`has${controller.capitalize(descriptor.target)}Target`]) {
          controller[`${descriptor.target}Target`].value = ''
        }
      }

      for (const component of place.address_components) {
        const addressType = component.types[0]
        const descriptor = klass.componentForm[addressType]

        if (descriptor) {
          const val = component[descriptor.field]
          if (controller[`has${controller.capitalize(descriptor.target)}Target`]) {
            controller[`${descriptor.target}Target`].value = val
          }
        }

        if (addressType === 'country' && controller.hasCountryCodeTarget) {
          controller.countryCodeTarget.value = component.short_name
          controller.countryCodeTarget.dispatchEvent(new Event('change'))
        }
      }

      if (place.geometry) {
        const location = place.geometry.location

        if (controller.hasLatitudeTarget) {
          controller.latitudeTarget.value = location.lat()
        }

        if (controller.hasLongitudeTarget) {
          controller.longitudeTarget.value = location.lng()
        }
      }

      if (controller.hasRawAddressTarget) {
        controller.rawAddressTarget.value = place.formatted_address
      }

      if (controller.hasFormattedAddressTarget) {
        controller.formattedAddressTarget.value = place.formatted_address
      }

      if (controller.hasFormTarget) {
        controller.formTarget.dispatchEvent(new Event('completion_complete'))
      }
    }
  }
}
