import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['orderId', 'errorMessage']
  static values = { url: String, roundId: String, source: String }

  search () {
    const url = new URL(this.urlValue)
    const orderId = this.orderIdTarget.value
    const roundId = this.roundIdValue
    const source = this.sourceValue
    const params = { order_id: orderId, round_id: roundId, source: source }
    url.search = new URLSearchParams(params).toString()
    if (orderId === '') {
      this.errorMessageTarget.innerHTML = 'Please enter the order #ID'
    } else {
      fetch(url)
        .then(response => response.json())
        .then(({ text, status, action }) => {
          if (status === 404) {
            this.errorMessageTarget.innerHTML = text
          } else {
            if (action === 'receptions') {
              window.location = `/${action}/${orderId}?round_id=${roundId}&source=round`
            } else {
              window.location = `/${action}/${orderId}?round_id=${roundId}`
            }
          }
        })
    }
  }

  clearErrorMessage () {
    this.errorMessageTarget.innerHTML = ''
  }
}
