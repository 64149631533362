const prefix = 'tw_modal';

export const events = {
  openModal: 'open_modal',
  closeModal: 'close_modal',
  hideLoadingIcon: 'hide_loading_icon',
  showLoadingIcon: 'show_loading_icon',
}

export const utils = {
  getEventName({eventName, contextId = Date.now()}) {
    return `${prefix}:${contextId}:${eventName}`;
  },
  getOpenEventName({contextId}) {
    return this.getEventName({eventName: events.openModal, contextId});
  },
  getCloseEventName({contextId}) {
    return this.getEventName({eventName: events.closeModal, contextId});
  },
  getHideLoadingIconEventName({contextId}) {
    return this.getEventName({eventName: events.hideLoadingIcon, contextId});
  },
  getShowLoadingIconEventName({contextId}) {
    return this.getEventName({eventName: events.showLoadingIcon, contextId});
  }
}
