import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["category", "incidentTag", "tagData"]

  connect() {
    this.updateAllIncidentTags()
  }

  updateIncidentTags(event) {
    const categorySelect = event.target
    const incidentFieldset = categorySelect.closest('fieldset.inputs')
    if (!incidentFieldset) return

    const tagSelect = incidentFieldset.querySelector('[data-incident-target="incidentTag"]')
    if (!tagSelect) return

    this.updateTagOptions(categorySelect, tagSelect)
  }

  updateAllIncidentTags() {
    this.categoryTargets.forEach(categorySelect => {
      const incidentFieldset = categorySelect.closest('fieldset.inputs')
      if (!incidentFieldset) return

      const tagSelect = incidentFieldset.querySelector('[data-incident-target="incidentTag"]')
      if (!tagSelect) return

      this.updateTagOptions(categorySelect, tagSelect)
    })
  }

  updateTagOptions(categorySelect, tagSelect) {
    const tagData = JSON.parse(this.tagDataTarget.dataset.tags)
    const selectedCategory = categorySelect.value
    const tags = tagData[selectedCategory] || []
    const selectedTags = JSON.parse(tagSelect.dataset.selectedTags || '[]')

    tagSelect.innerHTML = tags.map(tag =>
      `<option value="${tag[1]}" ${selectedTags.includes(tag[1]) ? 'selected' : ''}>${tag[0]}</option>`
    ).join('')
  }
}
