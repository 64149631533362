import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'wrapper', 'content' ];

  notify ({ text, type }) {
    const classByType = {
      error: 'danger', success: 'success', primary: 'primary', warning: 'warning'
    };

    this.wrapperTarget.classList.remove(Object.values(classByType));
    this.wrapperTarget.classList.add(`alert-${classByType[type]}`);

    this.contentTarget.innerHTML = text;
    this.element.style.display = 'block';
  }
}
