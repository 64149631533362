import ApplicationController from './application_controller'

export default class TurboFrameMissingController extends ApplicationController {
  connect () {
    document.addEventListener("turbo:frame-missing", (event) => {
      const { detail: { response, visit } } = event;
    
      event.preventDefault();
      visit(response.url);
    });
  }
}
