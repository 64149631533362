import ApplicationController from './application_controller'

/*
 * Values:
 *   toggleableElementId: The CDD ID of the toggleable element.
 *   visibilityClass: The CSS class that shows/hide the element.
 *
 * Important: the data-controller must be positioned on a checkbox.
 *
 * EXAMPLE:
 *
 * <%= f.check_box :insured, data: {
 *   controller: 'toggle',
 *   action: 'change->toggle#refreshVisibility',
 *   'toggle-toggleable-element-id-value': "js-toggleable-insurance"
 * } %>
 *
 * <span id="js-toggleable-insurance">
 *     ...
 * </span>
 */
export default class extends ApplicationController {
  static targets = ['content']
  static values = {
    toggleableElementId: String,
    inverted: Boolean,
    visibilityClass: String,
    names: Array
  }

  connect () {
    if (this.hasToggleableElementIdValue) {
      this.toggleableElement = document.getElementById(
        this.toggleableElementIdValue
      )
      this.refreshVisibility()
    }
  }

  refreshVisibility () {
    if (this.toggleableElement) {
      this.toggleableElement.classList.toggle(
        this.visibilityClassValue || 'hidden',
        this.invertedValue ? this.element.checked : !this.element.checked
      )
    }
  }

  toggleClassList () {
    for (const name of this.namesValue) {
      for (const content of this.contentTargets) {
        content.classList.toggle(name)
      }
    }
  }
}
