import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['timer']
  static values = { url: String }

  connect () {
    window.setInterval(() => { this.setTimer() }, 10000)
  }

  setTimer () {
    let i = 10
    var timer = setInterval(() => {
      --i
      this.timerTarget.innerHTML = i
      if (i === 0) clearInterval(timer)
    }, 1000) // 1000 milliseconds (1 second)

    this.refresh()
  }

  refresh () {
    fetch(`${this.urlValue}`)
  }
}
