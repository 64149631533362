import ApplicationController from './application_controller'

import QrScanner from 'qr-scanner'

export default class extends ApplicationController {
  static targets = ['video', 'resourceId', 'resourceType']

  connect () {
    this.qrScanner = new QrScanner(this.videoTarget, result => this.scannedItem(result))
    this.qrScanner.start()
  }

  scannedItem (payload) {
    var data = JSON.parse(payload)

    this.resourceIdTarget.value = data.id
    this.resourceTypeTarget.value = data.type
    this.resourceTypeTarget.form.submit()
  }
}
