import ApplicationController from './application_controller'

/**
 * Target:
 * source: The value of the source will be copied to the clipboard
 * 
 * Example simple copy:
 * <div data-controller="clipboard">
 *  <button data-action="clipboard#copy">Copy to clipboard</button>
 *  <input data-clipboard-target="source" type="text" value="Ayaz">
 * </div>
 * 
 * Example simple copy with a custom feedback:
 * <div data-controller="clipboard"
 *      data-clipboard-feedback-hidden-class-value="hidden"
 *      data-clipboard-content-to-copy-value="http://example.com">
 *  <button data-action="clipboard#copyWithFeedback">Copy URL</button>
 *  <div data-clipboard-target="feedback" class="hidden">
 *    URL successfully copied
 *  </div>
 * </div>
 * 
 * Example copy from value:
 * <a href="#" data-controller="clipboard" data-action="clipboard#copy"
 *             data-clipboard-content-to-copy-value="https://thepackengers.com">
 *  Copy Link
 * </a>
 */

export default class extends ApplicationController {
  static targets = ['source', 'feedback']
  static values = { feedbackHiddenClass: String, contentToCopy: String }
  #feedbackTimeout = null;

  copy(e) {
    e.preventDefault()

    navigator.clipboard.writeText(this.#clipboardContent())
    this.element.blur()
  }

  copyWithFeedback(e) {
    this.copy(e)

    if (this.#feedbackTimeout) {
      clearTimeout(this.#feedbackTimeout)
    }
    else {
      this.#toggleFeedbackHiddenClass()
    }

    this.#feedbackTimeout = setTimeout(() => {
      this.#feedbackTimeout = null;
      this.#toggleFeedbackHiddenClass();
    }, 3000)
  }

  #toggleFeedbackHiddenClass() {
    this.feedbackTargets.forEach(elem => {
      elem.classList.toggle(this.feedbackHiddenClassValue)
    })
  }

  #clipboardContent() {
    return this.hasContentToCopyValue ? this.contentToCopyValue : this.sourceTarget.value;
  }
}
