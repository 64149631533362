// const directionsService = new google.maps.DirectionsService()
// const directionsRenderer = new google.maps.DirectionsRenderer()

export function initMap (target, zoom = 8) {
  return new google.maps.Map(target, {
    zoom: zoom
  })
}

/*
 * addresses: Array<string>
 */
export function directionRouteWaypointsFromAddresses (addresses) {
  let waypoints = []

  addresses.forEach((address) => {
    waypoints.push({
      location: address,
      stopover: true
    })
  })

  return waypoints
}

export function buildRouteAndSetDirections (
  directionsService,
  directionsRenderer,
  origin,
  destination,
  waypoints,
  travelMode = google.maps.TravelMode.DRIVING
) {
  directionsService
    .route({
      origin: origin,
      destination: destination,
      waypoints: waypoints,
      travelMode: travelMode
    })
    .then((response) => {
      directionsRenderer.setDirections(response)
    })
    .catch((e) => 'Directions request failed due to ' + e)
}

export function addMarkers (map, latLngList) {
  let markers = []

  latLngList.forEach((latLng) => {
    markers.push(
      new google.maps.Marker({
        position: latLng,
        map: map
        // fillColor: 'yellow'
      })
    )
  })

  return markers
}

export function centerMapOnMarkers (map, markers) {
  let bounds = new google.maps.LatLngBounds()

  markers.forEach(function (marker) {
    bounds.extend(marker.position)
  })

  map.fitBounds(bounds)
}

export function drawLineBetweenMarkers (map, markers) {
  const lineSymbol = {
    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW
  }

  markers.forEach((marker, index) => {
    const nextMarker = markers[index + 1]

    if (nextMarker === undefined) { return }

    new google.maps.Polyline({
      path: [
        marker.position,
        nextMarker.position
      ],
      icons: [{
        icon: lineSymbol,
        offset: '100%'
      }],
      map: map
    })
  })
}
