import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'id',
    'name',
    'email',
    'phone',
    'tax',
    'vat',
    'contactName',
    'contactEmail',
    'contactPhone',
    'delivery',
    'countryCode',
    'otherInformation',
    'streetNumber',
    'route',
    'locality',
    'country',
    'postalCode',
    'state',
    'rawAddress',
    'latitude',
    'longitude'
  ];

  static values = { orderId: String }

  connect () {
    this.element.addEventListener(
      'autocomplete.change',
      this.autocomplete.bind(this)
    )
  }

  /**
   * Event called when the user clicks on a customer's email
   * Fills the customer's information in the form
   */
  autocomplete (e) {
    // Fetch the last order of the customer
    this.fetchCustomerLastOrder(this.idTarget.value).then((customer) => {
      this.nameTarget.value = customer.full_name
      this.contactNameTarget.value = customer.full_name

      this.contactEmailTarget.value = customer.email

      if (customer.phone === null) {
        this.phoneTarget.value = customer.last_order.phone
        this.contactPhoneTarget.value = customer.last_order.phone
      } else {
        this.phoneTarget.value = customer.phone
        this.contactPhoneTarget.value = customer.phone
      }

      this.taxTarget.value = customer.tax
      this.vatTarget.value = customer.vat
      if (customer.default_address) {
        this.fillFields(customer.default_address)
      }
    })
  }

  /**
   * Fetches the customer's last order
   * @param {*} id
   * @returns
   */
  fetchCustomerLastOrder (id) {
    const csrfToken = document.querySelector("meta[name='csrf-token']")
      .attributes.content.value

    return fetch(`/fr/customers/${id}`, {
      method: 'GET',
      headers: { 'X-CSRF-Token': csrfToken },
      credentials: 'same-origin'
    })
      .then((response) => response.json())
      .catch((err) => console.error(err))
  }

  autocompleteAddress (address) {
    if (address != null) {
      this.fillHiddenFields(address)
      this.fillInstructionField(address)
    }
  }

  fillFields (address) {
    this.countryCodeTargets.map((countryCodeTarget) => {
      countryCodeTarget.value = address.country_code
    })
    this.postalCodeTargets.map((postalCodeTarget) => {
      postalCodeTarget.value = address.postal_code
    })
    this.contactNameTarget.value = this.nameTarget.value
    this.contactEmailTarget.value = this.emailTarget.value
    this.contactPhoneTarget.value = this.phoneTarget.value
    this.deliveryTarget.value = address.raw_address
    this.streetNumberTarget.value = address.number
    this.routeTarget.value = address.route
    this.localityTarget.value = address.city
    this.countryTarget.value = address.country
    this.stateTarget.value = address.state
    this.rawAddressTarget.value = address.raw_address
    this.latitudeTarget.value = address.latitude
    this.longitudeTarget.value = address.longitude
    this.otherInformationTarget.value = address.other_information
  }

  fillInAddressContact () {
    if (this.orderIdValue === '') {
      this.contactNameTarget.value = this.nameTarget.value
      this.contactEmailTarget.value = this.emailTarget.value
      this.contactPhoneTarget.value = this.phoneTarget.value
    }
  }
}
