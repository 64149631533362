import { Controller } from "@hotwired/stimulus";

export default class FieldsetController extends Controller {
  onChange(ev) {
    this.element.querySelectorAll('label').forEach((label) => {
      const icon = label.querySelector("svg");
      const paragraph = label.querySelector("p");
      const isChecked = ev.target.checked;
      const activeParagraphClass = 'text-gray-600';
      const regularParagraphClass = 'text-gray-500';
      if(label.getAttribute("for") === ev.target.id) {
        icon.classList.toggle("hidden", !isChecked);
        if (!isChecked) {
          paragraph.classList.remove(activeParagraphClass);
          paragraph.classList.add(regularParagraphClass);
        } else {
          paragraph.classList.add(activeParagraphClass);
          paragraph.classList.remove(regularParagraphClass);
        }
      } else {
        icon.classList.toggle("hidden", isChecked);
        paragraph.classList.remove(activeParagraphClass);
        paragraph.classList.add(regularParagraphClass);
      }
    });
  }
}