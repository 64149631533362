import ApplicationController from './application_controller'

export default class ActiveAdminModalController extends ApplicationController {
  static targets = ['form']
  static values = { url: String, content: String, title: String, closeModal: String }

  connect () {
    if (this.hasCloseModalValue) {
      document.querySelector('.ui-dialog').remove()
      document.querySelector('.ui-widget-overlay').remove()
    }
  }

  show (e) {
    e.preventDefault()
    e.stopPropagation();

    ActiveAdmin.ModalDialog(this.titleValue, {}, (inputs) => console.log('File was download'))
    let dialogModal = document.querySelector('[role="dialog"]')
    dialogModal.id = 'downloadInComing'
    dialogModal.insertAdjacentHTML('beforeend', this.contentValue)
    Array.from(document.getElementsByClassName('ui-dialog-buttonset')).forEach(element => { element.style = 'display: none;' });
  }
}
