import { Controller } from "@hotwired/stimulus"

/**
 * OBJECTIVE: Manage form submission behavior with options for auto-submit, validation prevention, 
 * and specific button-triggered submission.
 *
 * EXAMPLE 1: Automatically submit the form when an input field value changes
 * <form>
 *   <input type="text" name="username" data-controller="submit-form" 
 *                                      data-action="change->submit-form#submit"
 *                                      data-submit-form-auto-submit-value=true>
 * </form>
 *
 * EXAMPLE 2: Automatically submit the form when an input field value changes, but prevent form validation
 * <form>
 *   <input type="text" name="username" data-controller="submit-form"
 *          data-action="change->submit-form#submit"
 *          data-submit-form-auto-submit-value="true"
 *          data-submit-form-prevent-validation-value="true">
 * </form>
 *
 * EXAMPLE 3: Submit the form when a button is clicked
 * <form>
 *   <button type="button" data-controller="submit-form"
 *           data-submit-form-button-submit-value="true"
 *           data-action="click->submit-form#submit">Submit</button>
 * </form>
 */

export default class extends Controller {
  static values = { 
    autoSubmit: { type: Boolean, default: false },
    preventValidation: { type: Boolean, default: false },
    buttonSubmit: { type: Boolean, default: false }
  }

  connect () {
    this.form = this.element.closest('form')

    if (!this.buttonSubmitValue) {
      this.oldValue = this.element.type === 'radio' ? this.element.checked : this.element.value
    }
  }

  submit() {
    this.autoSubmitValue ? this.#autoSubmit() : this.#defaultSubmit()
  }

  #autoSubmit() {
    if (!this.buttonSubmitValue) {
      const elementValue = this.element.type === 'radio' ? this.element.checked : this.element.value

      if (elementValue === this.oldValue) return
    }

    if (this.preventValidationValue) {
      this.#preventFormValidation()
    }
    const fieldSubmitter = document.getElementById('field_submitter')
    fieldSubmitter.value = this.#inputElementName(this.element.name)
    this.form.requestSubmit()
  }

  #defaultSubmit() {
    this.form.submit()
  }

  #preventFormValidation() {
    this.form.setAttribute('novalidate', '')
  }

  #inputElementName(htmlInputElementName) {
    let match = htmlInputElementName.match(/\[([^\]]+)\]$/);
    return match ? match[1] : null
  }
}