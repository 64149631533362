import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'hide', 'noPrint' ]
  static values = { 'fullDetails': Boolean }

  toggleDetails () {
    if (this.fullDetailsValue) {
      this.hideDetails()
    } else {
      this.showDetails()
    }

    this.fullDetailsValue = !this.fullDetailsValue
    event.currentTarget.innerHTML = event.currentTarget.dataset[this.fullDetailsValue]
  }

  print () {
    this.showDetails()
    setTimeout(() => {
      window.print()
    }, 100)
  }

  showDetails () {
    this.hideTargets.forEach((element) => {
      element.classList.add('d-block')
      element.classList.remove('d-none')
      setTimeout(() => {
        element.style.opacity = 1
      }, 100)
    })
  }

  hideDetails () {
    this.hideTargets.forEach((element) => {
      element.style.opacity = 0
      setTimeout(() => {
        element.classList.add('d-none')
        element.classList.remove('d-block')
      }, 100)
    })
  }
}
