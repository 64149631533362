import ApplicationController from './application_controller'

/**
 * OBJECTIVE: Switch class of elements betwen their activeClass and inactiveClass when
 * calling the switch method
 *
 * EXAMPLE:
 * <div data-controller="switch-class" data-value1="" data-value2="">
 *  <%= button_tag(id: 'example', type: 'button', role: 'switch',
      data: {
        action: 'switch-input-type#switch',
        'switch-input-type-target': 'element'
      },
    class: 'bg-green-700') do %>
      <span data-switch-class-target="element" data-active-class="bg-green-700" data-inactive-class="bg-red-700">Example</span>
    <% end %>
 * </div>
 */

export default class extends ApplicationController {
  static targets = ['input']
  static values = { typeToConvert: String, typeConverted: String }

  switch () {
    let elements = this.inputTargets

    if (this.hasTypeToConvertValue && this.hasTypeConvertedValue) {
      elements.forEach(element => this.toggleTypes(element))
    }
  }

  toggleTypes (element) {
    element.type = (element.type === this.typeToConvertValue ? this.typeConvertedValue : this.typeToConvertValue) 
  }
}
