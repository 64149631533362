import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { url: String }

  inTransit () {
    const url = new URL(this.urlValue)
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes.content.value
    fetch(url, { method: 'PUT', headers: { 'X-CSRF-Token': csrfToken } })
      .then(response => response.json())
  }
}
