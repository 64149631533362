import ApplicationController from './application_controller'
import SlimSelect from 'slim-select'

require('slim-select/dist/slimselect.min.css')

export default class extends ApplicationController {
    static targets = ['select']

    connect () {
      if (this.hasSelectTarget) {
        this.select = this.fillSelect()
      }
    }

    fillSelect () {
      this.selectTarget.classList.remove('form-control')
      return new SlimSelect({
        select: this.selectTarget,
        placeholder: this.selectTarget.dataset.placeholder
      })
    }
}
