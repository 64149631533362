import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['typewriter', 'block'];

    connect() {
        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.typewriterTarget.classList.add('typewriter');

                    setTimeout(() => {
                        this.blockTargets.forEach(item => {
                            item.classList.replace('opacity-0', 'opacity-100')
                        })
                    }, 3500);
                }
            });
        }, { threshold: 0.25 });

        this.observer.observe(this.typewriterTarget);
    }

    disconnect() {
        this.observer.unobserve(this.typewriterTarget);
    }
}
